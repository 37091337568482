import { cn } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { InputRequired } from '../input-required';

type Props = {
    label?: string;
    selectedOption?: number;
    options: {
        value: number;
        label: string;
    }[];
    onChange: (value: number) => void;
    className?: string;
    variant?: 'default' | 'primary';
    required?: boolean;
};

export const RadioGroup = ({
    options,
    label,
    selectedOption,
    onChange,
    className,
    variant = 'default',
    required = true,
}: Props) => {
    const { t } = useTranslation();

    return (
        <div className={cn('flex flex-col', className)}>
            <p className="lg:w-48 md:text-lg text-base text-txt font-medium mb-2 md:mb-4 pt-2">
                {label}
                {required && <InputRequired />}
            </p>
            <div className={cn('flex flex-wrap gap-3')}>
                {options.map((option) => (
                    <button
                        type="button"
                        key={option.value}
                        onClick={() => onChange(option.value)}
                        className={cn(
                            variantClasses[variant],
                            selectedOption === option.value &&
                                (variant === 'primary'
                                    ? 'bg-brend text-white border-brend'
                                    : 'bg-brend-20 !text-txt'),
                        )}
                    >
                        {t(option.label)}
                    </button>
                ))}
            </div>
        </div>
    );
};

const variantClasses = {
    primary:
        'border min-w-[120px] sm:min-w-[142px] h-fit px-2 md:px-5 py-2 rounded-[20px] bg-white text-txt-80 border-txt-60',
    default:
        'p-1.5 md:p-2.5 duration-100 border border-txt-80 text-center text-sm md:text-base text-txt-60 rounded cursor-pointer ',
};
