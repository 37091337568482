import { SelectUnderline } from 'components/common/custom-select/default';
import { InputRequired } from 'components/common/input-required';
import InputUnderline from 'components/common/input/underline';
import { PhoneInput } from 'components/common/phone-input';
import { RenderIf } from 'components/common/render-if';
import { CreateAdvertFormValues } from 'pages/advert-create/types';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/features/userSlice';

type Props = {
    form: UseFormReturn<CreateAdvertFormValues>;
};

const AboutAnouncerStep = ({ form }: Props) => {
    const { t } = useTranslation();
    const { user } = useSelector(selectUser);
    const {
        register,
        formState: { errors },
    } = form;

    useEffect(() => {
        if (user) {
            form.setValue('name', user.name);
            form.setValue('surname', user.surname);
            form.setValue('email', user.email);
            form.setValue('phoneNumber', user.phoneNumber);
        }
    }, [user]);

    console.log('user', errors);

    return (
        <div className={`px-4 md:px-6 lg:px-8 border-t-2 border-txt-40 duration-200`}>
            <div className="py-3 md:py-5 lg:py-7 flex justify-between items-center cursor-pointer">
                <h2 className="lg:text-3xl font-bold text-txt">{t('advert.about-anouncer')}</h2>
            </div>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-2">
                <div>
                    <h5 className="md:text-lg text-base text-txt font-medium">
                        {t('auth.name')}
                        <InputRequired />
                    </h5>
                    <div className="mb-5 md:mb-8 mt-3 md:mt-6">
                        <InputUnderline
                            {...register('name')}
                            error={errors.name?.message as string}
                            placeholder={t('general.enter')}
                            className="w-full"
                            disabled={!!user}
                        />
                    </div>
                </div>
                <div>
                    <h5 className="md:text-lg text-base text-txt font-medium">
                        {t('auth.surname')}
                        <InputRequired />
                    </h5>
                    <div className="mb-5 md:mb-8 mt-3 md:mt-6">
                        <InputUnderline
                            {...register('surname')}
                            error={errors.surname?.message as string}
                            placeholder={t('general.enter')}
                            className="w-full"
                            disabled={!!user}
                        />
                    </div>
                </div>
                <div>
                    <h5 className="md:text-lg text-base text-txt font-medium">
                        {t('auth.email')}
                        <InputRequired />
                    </h5>
                    <div className="mb-5 md:mb-8 mt-3 md:mt-6">
                        <InputUnderline
                            {...register('email')}
                            error={errors.email?.message as string}
                            placeholder={t('general.enter')}
                            className="w-full"
                            disabled={!!user}
                        />
                    </div>
                </div>
                <RenderIf condition={!user}>
                    <div>
                        <h5 className="md:text-lg text-base text-txt font-medium">
                            {t('auth.phone-number')}
                            <InputRequired />
                        </h5>
                        <div className="mb-5 md:mb-8 mt-4 md:mt-7">
                            <PhoneInput
                                defaultCountry="AZ"
                                international
                                placeholder="Enter a phone number"
                                onChange={(value) => form.setValue('phoneNumber', value)}
                                disabled={!!user}
                            />
                            <p className="text-red-600 text-xs h-4 mt-1">
                                {errors.phoneNumber?.message}
                            </p>
                        </div>
                    </div>
                </RenderIf>
            </div>
        </div>
    );
};

export default AboutAnouncerStep;
