import { create } from 'zustand';

export type MobileTab =
    | 'home'
    | 'bookmars'
    | 'createAdvert'
    | 'search'
    | 'login'
    | 'register'
    | 'profile';

export interface MobileTabStore {
    tab: MobileTab;
    onTabChange: (type: MobileTab) => void;
}

export const useMobileTab = create<MobileTabStore>((set) => ({
    tab: 'home',
    onTabChange: (tab) => set({ tab }),
}));
