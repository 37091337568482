import { create } from 'zustand';

export interface LanguageStore {
    selectedLanguageId: string | null;
    selectedLanguageCode: string | null;
    onLanguageIdChange: (languageId: string | null, languageCode: string | null) => void;
}

export const useLanguage = create<LanguageStore>((set) => ({
    selectedLanguageId: null,
    selectedLanguageCode: null,
    onLanguageIdChange: (languageId, languageCode) =>
        set({ selectedLanguageId: languageId, selectedLanguageCode: languageCode }),
}));
