import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ButtonOutline from 'components/common/button/outline';
import CustomSelectSecondary from 'components/common/custom-select/secondary';
import { useSidebarContext } from 'contexts/sidebar';
import { useModal } from 'hooks/use-modal';
import { selectUser } from 'store/features/userSlice';
import { RenderIf } from 'components/common/render-if';
import { UserButton } from 'components/common/user-button';
import { paths } from 'lib/constants/paths';
import { ISidebarContextType } from 'types';

import { ReactComponent as BurgerIcon } from 'assets/icons/burger.svg';
import { ReactComponent as BurgerOutlineIcon } from 'assets/icons/burger-outline.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as LogoImg } from 'assets/images/logo.svg';
import { ReactComponent as LightLogoImg } from 'assets/images/logo-light.svg';
import { BookmarkIcon, FilterIcon } from 'lucide-react';
import { useMobileTab } from 'hooks/use-mobile-tab';

const Navbar = () => {
    const { user } = useSelector(selectUser);
    const { onTabChange } = useMobileTab();
    const { onOpen } = useModal();
    const { t } = useTranslation();
    const { showSidebar } = useSidebarContext() as ISidebarContextType;

    const [selectedCountry, setSelectedCountry] = useState({
        value: 'azerbaijan',
        label: t('countries.azerbaijan'),
    });

    return (
        <nav className="bg-brend sticky top-0 backdrop-blur-lg md:bg-transparent z-20 py-[15px] md:pt-[34px]  mb-6 md:mb-[70px] px-5 md:px-[37px] lg:px-25 w-full mx-auto flex justify-between items-center">
            <SearchIcon
                onClick={() => onTabChange('search')}
                className="fill-[#F3F4F4] block md:hidden cursor-pointer"
            />
            <Link to={paths.home} className="flex items-center gap-x-2">
                <LogoImg className="hidden md:block" />
                <LightLogoImg className="block md:hidden" />
            </Link>
            <div className="hidden lg:flex items-center gap-x-1.5">
                <CustomSelectSecondary
                    selectedOption={selectedCountry}
                    options={[
                        { value: 'azerbaijan', label: t('countries.azerbaijan') },
                        { value: 'turkey', label: t('countries.turkey') },
                    ]}
                    onChange={(option) => setSelectedCountry(option)}
                />
                <Link
                    to={paths.agencies}
                    className="text-txt p-2.5 text-base font-medium hover:text-primary duration-100"
                >
                    {t('general.agencies')}
                </Link>
                <Link
                    to={paths.residentialComplexes.list}
                    className="text-txt p-2.5 text-base font-medium hover:text-primary duration-100"
                >
                    {t('general.residential-complexes')}
                </Link>
                <Link
                    to={'/'}
                    className="text-txt p-2.5 text-base font-medium hover:text-primary duration-100"
                >
                    {t('general.news')}
                </Link>
            </div>
            <div className="hidden md:flex items-center gap-x-2">
                <Link to={paths.advert.create}>
                    <ButtonOutline text={`+ ${t('general.create-advert')}`} />
                </Link>
                <ButtonOutline
                    text={<FilterIcon />}
                    disabled={false}
                    onClick={() => onOpen('sale-filter')}
                />
                <Link to="/">
                    <ButtonOutline text={<BookmarkIcon />} />
                </Link>
                <RenderIf condition={!!user}>
                    <UserButton />
                </RenderIf>
                <RenderIf condition={!user}>
                    <ButtonOutline
                        text={t('general.login')}
                        disabled={false}
                        onClick={() => onOpen('login')}
                    />
                </RenderIf>
                <BurgerOutlineIcon className="lg:hidden cursor-pointer" onClick={showSidebar} />
            </div>
            <BurgerIcon className="cursor-pointer block md:hidden" onClick={showSidebar} />
        </nav>
    );
};

export default Navbar;
