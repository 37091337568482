import React from 'react';

const ComingSoon = () => {
    return (
        <div className="min-h-[calc(100vh-166px)] bg-transparent flex flex-col items-center justify-center">
            <h1 className="text-5xl text-brend font-bold mb-8 animate-pulse">Coming Soon</h1>
            <p className="text-brend text-lg mb-8 text-center">
                We're working hard to bring you something amazing. Stay tuned!
            </p>
        </div>
    );
};

export default ComingSoon;
