import { SidebarProvider } from 'contexts/sidebar';
import Footer from 'layout/footer';
import Header from 'layout/header';
import LoginModal from 'layout/login-modal';
import MobileNavigation from 'layout/mobile/navbar';
import Navbar from 'layout/navbar';
import RegisterModal from 'layout/register-modal';
import Sidebar from 'layout/sidebar';
import AdvanceFilterModal from 'pages/home/components/advance-filter-modal';
import React from 'react';

interface RootProps {
    children: React.ReactNode;
    showFooter?: boolean;
}
const Root = ({ children, showFooter }: RootProps) => {
    return (
        <div className="relative min-h-full pb-[90px] md:pb-0">
            <SidebarProvider>
                <Header />
                <Navbar />
                <Sidebar />
                {children}
                {showFooter && (
                    <>
                        <MobileNavigation />
                        {/* in mobile not visible */}
                        <Footer />
                    </>
                )}
                {/* AdvanceFilterModal in mobile not visible */}
                <AdvanceFilterModal />

                {/* LoginModal in mobile not visible */}
                <LoginModal />

                {/* RegisterModal in mobile not visible */}
                <RegisterModal />
            </SidebarProvider>
        </div>
    );
};

export default Root;
