import { Navigate } from 'react-router-dom';

import { LoadingOverlay } from 'components/common/loading-overlay';
import { useGetApiV1ClientIdentityGetCurrentUserQuery } from 'service/api/allApis';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/features/userSlice';
import { useEffect } from 'react';

interface IAuthGuardProps {
    children: React.ReactNode;
    requireAuth?: boolean;
}

export const AuthGuard = ({ children, requireAuth }: IAuthGuardProps) => {
    const { user, token } = useSelector(selectUser);
    const { isFetching, isLoading, refetch } = useGetApiV1ClientIdentityGetCurrentUserQuery(
        undefined,
        {
            skip: !token,
        },
    );

    const loading = isFetching || isLoading;

    useEffect(() => {
        if (token) refetch();
    }, [token]);

    if (loading) {
        return <LoadingOverlay />;
    }

    if (requireAuth && !user) {
        return <Navigate to="/?require-auth=true" replace />;
    }

    return <>{children}</>;
};

export default AuthGuard;
