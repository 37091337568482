import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { store } from 'store';
import { router } from 'router';
import { Toaster } from 'sonner';

function App() {
    return (
        <Provider store={store}>
            <RouterProvider router={router} />
            <Toaster richColors />
        </Provider>
    );
}

export default App;
