import Modal from 'components/common/modal';
import { useModal } from 'hooks/use-modal';
import React from 'react';

import Filter from '../../../../components/filter';

const AdvanceFilterModal = () => {
    const { isOpen, type, onClose } = useModal();

    const isModalOpen = isOpen && (type === 'rent-filter' || type === 'sale-filter');
    const title = type === 'rent-filter' ? 'Kirayə' : 'Satış';

    return (
        <Modal
            onClose={onClose}
            isOpen={isModalOpen}
            modalContainerClassName="hidden md:block"
            modalClassName="w-[926px] lg:w-[1092px] px-4 lg:px-8 bg-white rounded-2xl md:gap-x-1 lg:gap-x-[42px] py-5"
        >
            <h1 className="text-brend text-[32px] leading-[44px] font-bold tracking-[3.84px]">
                {`${title} Axtarış`}
            </h1>
            <Filter advanced />
        </Modal>
    );
};

export default AdvanceFilterModal;
