import { cn } from 'lib/utils';
import React, { forwardRef } from 'react';

interface TextAreaUnderlineProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    placeholder: string;
    className?: string;
    error?: string;
}

const TextAreaUnderline = forwardRef<HTMLTextAreaElement, TextAreaUnderlineProps>(
    ({ placeholder, className, error, ...props }, ref) => {
        return (
            <div className="max-w-full">
                <div className="relative">
                    <textarea
                        placeholder={placeholder}
                        className={cn(
                            'w-full py-2 px-2.5 border-b border-txt-40 placeholder:text-txt-40 placeholder:font-medium outline-none hover:border-primary/70 focus:border-primary duration-100',
                            className,
                        )}
                        ref={ref}
                        {...props}
                    />
                </div>
                <p className="text-red-600 text-xs h-4 mt-1">{error}</p>
            </div>
        );
    },
);

export default TextAreaUnderline;
