import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'lib/constants/keys';
import token from 'lib/token';
import { allApis } from 'service/api/allApis';
import { TTokenResponse, TUser } from 'types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IUserState {
    user: TUser | null;
    token: string | null;
}

const initialState: IUserState = {
    user: null,
    token: token.getToken(ACCESS_TOKEN_KEY),
};

export const userSlice = createSlice({
    initialState,
    name: 'userSlice',
    reducers: {
        logout: () => {
            token.removeToken(ACCESS_TOKEN_KEY);
            token.removeToken(REFRESH_TOKEN_KEY);
            return {
                user: null,
                token: null,
            };
        },
        setUser: (state, action: PayloadAction<TUser>) => {
            state.user = action.payload;
        },
        setAccessToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
            token.setToken(ACCESS_TOKEN_KEY, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            allApis.endpoints.postApiV1ClientIdentityLogin.matchFulfilled,
            (state, action) => {
                const payload = action.payload as TTokenResponse;
                state.token = payload.accessToken;
                token.setToken(ACCESS_TOKEN_KEY, payload.accessToken);
                token.setToken(REFRESH_TOKEN_KEY, payload.refreshToken);
            },
        );
        builder.addMatcher(
            allApis.endpoints.getApiV1ClientIdentityGetCurrentUser.matchFulfilled,
            (state, action) => {
                const { user } = action.payload as { user: TUser };
                state.user = user;
            },
        );
    },
});

export default userSlice.reducer;

export const selectUser = (state: { userState: IUserState }) => state.userState;

export const { logout, setUser, setAccessToken } = userSlice.actions;
