import Register from 'components/register';
import { useMobileTab } from 'hooks/use-mobile-tab';
import React from 'react';

const MobileRegisterTab = () => {
    const { onTabChange } = useMobileTab();
    return (
        <div className="flex justify-center md:hidden w-full">
            <Register onLoginOpen={() => onTabChange('login')} />
        </div>
    );
};

export default MobileRegisterTab;
