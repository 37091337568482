import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import moment from 'moment';
import 'moment/min/locales';

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export const formatPrice = (price?: number | null) => {
    if (!price) return '';
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const formatDynamicDate = (inputDate: string, locale = 'az') => {
    moment.locale(locale);
    return moment(inputDate).fromNow();
};
