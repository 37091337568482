import Hashtag from 'components/common/hashtag';
import { useMobileTab } from 'hooks/use-mobile-tab';
import MobileBookmarksTab from 'layout/mobile/bookmarks-tab';
import MobileCreateAdverTab from 'layout/mobile/create-advert-tab';
import MobileLoginTab from 'layout/mobile/login-tab';
import MobileRegisterTab from 'layout/mobile/register-tab';
import MobileSearchTab from 'layout/mobile/search-tab';
import AllAdverts from './components/all-adverts';
import LandingSlider from './components/landing-slider';
import PremiumAdvertSlider from './components/premium-advert-slider';
import { useCallback, useEffect } from 'react';
import VipAdverts from './components/vip-adverts';
import TopAdvertisings from './components/top-advertisings';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Filter from 'components/filter';

const HomePage = () => {
    const { tab, onTabChange } = useMobileTab();
    const [searchParams, setSearchParams] = useSearchParams();
    const requireAuth = !!searchParams.get('require-auth');
    const { t } = useTranslation();
    const onSizeChange = useCallback(() => {
        if (window.innerWidth >= 925 && tab !== 'home') {
            onTabChange('home');
        }
    }, [window.innerWidth, tab]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        window.removeEventListener('resize', onSizeChange);
        window.addEventListener('resize', onSizeChange);
        return () => window.removeEventListener('resize', onSizeChange);
    }, [tab]);

    useEffect(() => {
        if (window.innerWidth >= 925 && tab !== 'home') {
            onTabChange('home');
        }
    }, []);

    useEffect(() => {
        if (window.innerWidth < 925 && requireAuth) {
            onTabChange('login');
            searchParams.delete('require-auth');
            setSearchParams(searchParams);
        }
    }, [requireAuth]);

    return (
        <div>
            {tab === 'home' && (
                <>
                    <TopAdvertisings />
                    <div className="absolute top-0 left-0 w-full h-[900px] bg-home-small md:bg-home"></div>
                    <div className="md:w-[925px] lg:w-[1070px] mx-auto relative z-10 grid grid-cols-1 md:grid-cols-[525px_340px] lg:grid-cols-[600px_400px] justify-between items-start gap-x-5 lg:gap-x-[30px] lg:mb-[80px] md:mb-[70px] ">
                        <div className="block">
                            <Hashtag
                                label={t('general.residential-complexes')}
                                className="pl-5 md:mb-3 lg:mb-[14px] text-white md:text-brend"
                            />
                            <LandingSlider />
                        </div>
                        <div className="hidden md:block bg-white rounded-2xl shadow-[1px_4px_16px_0px_#00000026] pt-2.5 pb-10 mt-8">
                            <Filter />
                        </div>
                    </div>
                </>
            )}

            {/* PremiumAdvertSlider in mobile not visible */}
            <PremiumAdvertSlider />

            {tab === 'home' && (
                <>
                    <VipAdverts />
                    <AllAdverts />
                </>
            )}
            {tab === 'login' && <MobileLoginTab />}
            {tab === 'register' && <MobileRegisterTab />}
            {tab === 'search' && <MobileSearchTab />}
            {tab === 'bookmars' && <MobileBookmarksTab />}
            {tab === 'createAdvert' && <MobileCreateAdverTab />}
        </div>
    );
};

export default HomePage;
