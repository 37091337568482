import Filter from 'components/filter';
import React from 'react';

const MobileSearchTab = () => {
    return (
        <div className="max-w-[400px] mx-auto pb-4">
            <Filter advanced />
        </div>
    );
};

export default MobileSearchTab;
