import React from 'react';

interface RangeProps {
    label: string;
}

const Range = ({ label }: RangeProps) => {
    return (
        <div className="flex flex-col gap-y-1">
            <p className="font-medium text-txt">{label}</p>
            <div className="grid grid-cols-2 h-[42px] w-full">
                <input
                    type="number"
                    className="h-full bg-dark-05 pl-1 outline-none border border-transparent focus:border-primary border-r-txt-40"
                    placeholder="min."
                    style={{
                        borderTopLeftRadius: '10.4px',
                        borderBottomLeftRadius: '10.4px',
                    }}
                />
                <input
                    type="number"
                    className="h-full bg-dark-05 pl-1 outline-none border border-transparent focus:border-primary"
                    style={{
                        borderTopRightRadius: '10.4px',
                        borderBottomRightRadius: '10.4px',
                    }}
                    placeholder="max."
                />
            </div>
        </div>
    );
};

export default Range;
