import { cn } from 'lib/utils';
import React from 'react';
import { IOption } from 'types';

interface FiltersProps {
    options: IOption[];
    selectedOption: IOption;
    onOptionChange: (option: IOption) => void;
    wrapperClassName?: string;
}

const Filters = ({ options, selectedOption, onOptionChange, wrapperClassName }: FiltersProps) => {
    return (
        <div
            className={cn(
                'w-full flex flex-wrap justify-center gap-2 md:gap-3 lg:gap-5 my-10 text-sm md:text-base',
                wrapperClassName,
            )}
        >
            {options.map((option) => (
                <button
                    key={option.value}
                    style={{
                        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.10)',
                    }}
                    className={cn(
                        'p-2 lg:px-5 flex items-center border border-transparent hover:border-primary/60',
                        selectedOption.value === option.value ? 'text-brend' : '#494749',
                    )}
                    onClick={() => onOptionChange(option)}
                >
                    {option.label}
                </button>
            ))}
        </div>
    );
};

export default Filters;
