import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { cn } from 'lib/utils';
import React, { useState } from 'react';

interface CustomSelectPrimaryProps {
    placeholder: string;
}

const CustomSelectPrimary = ({ placeholder }: CustomSelectPrimaryProps) => {
    const [isOpen /*setIsOpen*/] = useState(false);
    return (
        <div className="w-full">
            <div className="w-full text-txt-40 font-medium h-[49px] rounded-xl md:bg-dark-05 flex items-center justify-between px-5 cursor-pointer border-[0.433px] border-dark-20 md:border-none">
                {placeholder}
                <ArrowDownIcon
                    className={cn(
                        'duration-150 fill-txt-40 w-5 h-2.5 translate-y-0.5',
                        isOpen && 'rotate-180',
                    )}
                />
            </div>
        </div>
    );
};

export default CustomSelectPrimary;
