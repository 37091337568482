import { SelectUnderline } from 'components/common/custom-select/default';
import { InputRequired } from 'components/common/input-required';
import { GoogleMap } from 'components/common/map';
import { RenderIf } from 'components/common/render-if';
import { CreateAdvertFormValues } from 'pages/advert-create/types';
import React, { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    useGetApiV1ClientCitiesQuery,
    useGetApiV1ClientCountriesQuery,
    useGetApiV1ClientDistrictsQuery,
    useGetApiV1ClientMetrosQuery,
} from 'service/api/allApis';

type Props = {
    form: UseFormReturn<CreateAdvertFormValues>;
};

const AboutAddressStep = ({ form }: Props) => {
    const { t } = useTranslation();
    const {
        watch,
        formState: { errors },
    } = form;
    const [selectedCityName, setSelectedCityName] = React.useState<string | null>(null);
    const countryId = watch('countryId');
    const cityId = watch('cityId');
    const districtId = watch('districtId');
    const { data: countryData, isLoading: isCountriesLoading } = useGetApiV1ClientCountriesQuery({
        isPaginated: false,
    });
    const { data: cityData, isLoading: isCitiesLoading } = useGetApiV1ClientCitiesQuery(
        { isPaginated: false, countryId },
        { skip: !countryId },
    );
    const { data: districtData, isLoading: isDistrictLoading } = useGetApiV1ClientDistrictsQuery(
        { isPaginated: false, cityId },
        { skip: !cityId },
    );
    const { data: metroData, isLoading: isMetroLoading } = useGetApiV1ClientMetrosQuery(
        { isPaginated: false, cityId },
        { skip: !cityId },
    );

    const countryOptions = useMemo(() => {
        if (!countryData?.items) return [];
        return countryData.items.map((item) => ({ value: item.id!, label: item.name! }));
    }, [countryData]);

    const cityOptions = useMemo(() => {
        if (!cityData?.items) return [];
        return cityData.items.map((item) => ({ value: item.id!, label: item.name! }));
    }, [cityData]);

    const districtOptions = useMemo(() => {
        if (!districtData?.items) return [];
        return districtData.items.map((item) => ({ value: item.id!, label: item.name! }));
    }, [districtData]);

    const metroOptions = useMemo(() => {
        if (!metroData?.items) return [];
        return metroData.items.map((item) => ({ value: item.id!, label: item.name! }));
    }, [metroData]);

    return (
        <div className={`px-4 md:px-6 lg:px-8 border-b-0 border-txt-40 duration-200`}>
            <div className="py-3 md:py-5 lg:py-7 flex justify-between items-center cursor-pointer">
                <h2 className="lg:text-3xl font-bold text-txt">{t('advert.about-address')}</h2>
            </div>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-2">
                <div>
                    <h5 className="md:text-lg text-base text-txt font-medium ">
                        {t('general.country')}
                        <InputRequired />
                    </h5>
                    <div className="mb-5 md:mb-8 mt-3 md:mt-6 flex gap-3 flex-col sm:flex-row">
                        <SelectUnderline
                            options={countryOptions}
                            loading={isCountriesLoading}
                            onChange={(value) => {
                                form.setValue('countryId', value as string);
                                form.setValue('cityId', '');
                                form.setValue('districtId', '');
                                form.setValue('metroId', '');
                                form.clearErrors('countryId');
                            }}
                            value={watch('countryId')}
                            error={errors.countryId?.message as string}
                        />
                    </div>
                </div>
                <RenderIf condition={!!countryId}>
                    <div>
                        <h5 className="md:text-lg text-base text-txt font-medium ">
                            {t('general.city')}
                            <InputRequired />
                        </h5>
                        <div className="mb-5 md:mb-8 mt-3 md:mt-6 flex gap-3 flex-col sm:flex-row">
                            <SelectUnderline
                                options={cityOptions}
                                loading={isCitiesLoading}
                                onChange={(value) => {
                                    setSelectedCityName(
                                        cityOptions.find((item) => item.value === value)?.label ??
                                            null,
                                    );
                                    form.setValue('cityId', value as string);
                                    form.setValue('districtId', '');
                                    form.setValue('metroId', '');
                                    form.clearErrors('cityId');
                                }}
                                value={watch('cityId')}
                                error={errors.cityId?.message as string}
                            />
                        </div>
                    </div>
                </RenderIf>
                <RenderIf condition={!!cityId && !!districtOptions.length}>
                    <div>
                        <h5 className="md:text-lg text-base text-txt font-medium ">
                            {t('general.district')}
                        </h5>
                        <div className="mb-5 md:mb-8 mt-3 md:mt-6 flex gap-3 flex-col sm:flex-row">
                            <SelectUnderline
                                options={districtOptions}
                                loading={isDistrictLoading}
                                onChange={(value) => form.setValue('districtId', value as string)}
                                value={watch('districtId')}
                            />
                        </div>
                    </div>
                </RenderIf>

                <RenderIf condition={!!districtId && !!metroOptions.length}>
                    <div>
                        <h5 className="md:text-lg text-base text-txt font-medium ">
                            {t('general.metro')}
                        </h5>
                        <div className="mb-5 md:mb-8 mt-3 md:mt-6 flex gap-3 flex-col sm:flex-row">
                            <SelectUnderline
                                options={metroOptions}
                                loading={isMetroLoading}
                                onChange={(value) => form.setValue('metroId', value as string)}
                                value={watch('metroId')}
                            />
                        </div>
                    </div>
                </RenderIf>
            </div>
            <div className="pb-8">
                <h4 className="text-muted-foreground text-lg mb-4">
                    {t('general.mark-address-on-map')}
                </h4>
                <GoogleMap
                    city={selectedCityName}
                    onMarkerPositionChange={({ lat, lng }) => {
                        form.setValue('latitude', lat);
                        form.setValue('longitude', lng);
                    }}
                />
            </div>
        </div>
    );
};

export default AboutAddressStep;
