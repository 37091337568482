import { HashLoader } from 'react-spinners';

export const Spinner = ({
    size,
    color = 'white',
    loading = true,
}: {
    size: number;
    color?: string;
    loading?: boolean;
}) => {
    return (
        <HashLoader
            color={color}
            loading={loading}
            size={size}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
    );
};
