import { ReactComponent as AgentIcon } from 'assets/icons/agent.svg';
import { ReactComponent as BuildingIcon } from 'assets/icons/building.svg';
import { ReactComponent as NewsIcon } from 'assets/icons/news.svg';
import { ReactComponent as ServiceIcon } from 'assets/icons/service.svg';
import React from 'react';
import { Link } from 'react-router-dom';

const Navigation = () => {
    return (
        <div className="grid md:hidden w-full grid-cols-2 gap-4 mb-6">
            <Link
                to={'/'}
                className="flex flex-col gap-y-5 justify-center items-center h-[150px] border border-white"
                style={{
                    boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.25)',
                }}
            >
                <AgentIcon />
                <p className="text-txt text-[18px] font-medium tracking-[0.72px]">Aqentlik</p>
            </Link>
            <Link
                to={'/'}
                className="flex flex-col gap-y-5 justify-center items-center h-[150px] border border-white"
                style={{
                    boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.25)',
                }}
            >
                <BuildingIcon />
                <p className="text-txt text-[18px] font-medium tracking-[0.72px] w-3/4 text-center">
                    Yaşayış kompleksləri
                </p>
            </Link>
            <Link
                to={'/'}
                className="flex flex-col gap-y-5 justify-center items-center h-[150px] border border-white "
                style={{
                    boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.25)',
                }}
            >
                <NewsIcon />
                <p className="text-txt text-[18px] font-medium tracking-[0.72px] w-3/4 text-center">
                    Xəbərlər
                </p>
            </Link>
            <Link
                to={'/'}
                className="flex flex-col gap-y-5 justify-center items-center h-[150px] border border-white"
                style={{
                    boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.25)',
                }}
            >
                <ServiceIcon />
                <p className="text-txt text-[18px] font-medium tracking-[0.72px]">Xidmətlər</p>
            </Link>
        </div>
    );
};

export default Navigation;
