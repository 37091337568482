import advertTempImg from 'assets/images/temp-4.png';
import AdvertCard from 'components/common/advert-card';
import AdvertWrapper from 'layout/advert-wrapper';
import React, { useState } from 'react';
import { IOption } from 'types';

import Filters from '../../../../components/common/filters';
import Navigation from './navigation';
import { t } from 'i18next';
import { useGetApiV1ClientAdsQuery } from 'service/api/allApis';

const FILTERS = [
    {
        value: 'all',
        label: 'Bütün',
    },
    {
        value: 'new-buildings',
        label: 'Yeni tikililər',
    },
    {
        value: 'old-buildings',
        label: 'Köhnə tikililər',
    },
    {
        value: 'commercial-buildings',
        label: 'Ticarət obyektləri',
    },
    {
        value: 'residential-complexes',
        label: 'Yaşayış kompleksləri',
    },
    {
        value: 'country-house',
        label: 'Bağ evi',
    },
    {
        value: 'soil',
        label: 'Torpaq',
    },
];

export const ADVERTS = [
    {
        id: 1,
        title: 'Port Baku Residence',
        address: 'Xanlar küçəsi',
        price: '205 000 AZN',
        roomCount: 5,
        volume: '75 m²',
        floor: '13/14',
        repaired: true,
        createdAt: 'Bu gün 11:00',
        type: 'sale',
        hasMortgage: true,
        img: advertTempImg,
    },
    {
        id: 2,
        title: 'Port Baku Residence',
        address: 'Xanlar küçəsi',
        price: '205 000 AZN',
        roomCount: 5,
        volume: '75 m²',
        floor: '13/14',
        repaired: true,
        createdAt: 'Bu gün 11:00',
        type: 'sale',
        hasMortgage: true,
        img: advertTempImg,
    },
    {
        id: 3,
        title: 'Port Baku Residence',
        address: 'Xanlar küçəsi',
        price: '205 000 AZN',
        roomCount: 5,
        volume: '75 m²',
        floor: '13/14',
        repaired: true,
        createdAt: 'Bu gün 11:00',
        type: 'sale',
        hasMortgage: true,
        img: advertTempImg,
    },
    {
        id: 4,
        title: 'Port Baku Residence',
        address: 'Xanlar küçəsi',
        price: '205 000 AZN',
        roomCount: 5,
        volume: '75 m²',
        floor: '13/14',
        repaired: true,
        createdAt: 'Bu gün 11:00',
        type: 'sale',
        hasMortgage: true,
        img: advertTempImg,
    },
    {
        id: 5,
        title: 'Port Baku Residence',
        address: 'Xanlar küçəsi',
        price: '205 000 AZN',
        roomCount: 5,
        volume: '75 m²',
        floor: '13/14',
        repaired: true,
        createdAt: 'Bu gün 11:00',
        type: 'sale',
        hasMortgage: true,
        img: advertTempImg,
    },
    {
        id: 6,
        title: 'Port Baku Residence',
        address: 'Xanlar küçəsi',
        price: '205 000 AZN',
        roomCount: 5,
        volume: '75 m²',
        floor: '13/14',
        repaired: true,
        createdAt: 'Bu gün 11:00',
        type: 'sale',
        hasMortgage: true,
        img: advertTempImg,
    },
    {
        id: 7,
        title: 'Port Baku Residence',
        address: 'Xanlar küçəsi',
        price: '205 000 AZN',
        roomCount: 5,
        volume: '75 m²',
        floor: '13/14',
        repaired: true,
        createdAt: 'Bu gün 11:00',
        type: 'sale',
        hasMortgage: true,
        img: advertTempImg,
    },
    {
        id: 8,
        title: 'Port Baku Residence',
        address: 'Xanlar küçəsi',
        price: '205 000 AZN',
        roomCount: 5,
        volume: '75 m²',
        floor: '13/14',
        repaired: true,
        createdAt: 'Bu gün 11:00',
        type: 'sale',
        hasMortgage: true,
        img: advertTempImg,
    },
];

const AllAdverts = () => {
    const [selectedFilterOption, setSelectedFilterOption] = useState(FILTERS[0]);
    const { data, isLoading } = useGetApiV1ClientAdsQuery({
        isPaginated: false,
    });

    console.log(data);

    return (
        <div className=" w-full px-5 md:px-0 md:w-[925px] lg:w-[1240px] mx-auto z-[1] relative">
            <h2 className="text-brend text-center text-[32px] leading-[44px] font-bold tracking-[3.84px]">
                {t('general.all-adverts')}
            </h2>
            <h4 className="block md:hidden my-6 text-brend text-sm font-semibold tracking-[0.56px]">
                {t('general.quick-search')}
            </h4>
            <Filters
                options={FILTERS}
                selectedOption={selectedFilterOption}
                onOptionChange={(option: IOption) => setSelectedFilterOption(option)}
            />
            <Navigation />
            <AdvertWrapper>
                {isLoading && [...Array(8)].map((_, index) => <AdvertCard.Skeleton key={index} />)}
                {data?.items?.map((advert) => (
                    <AdvertCard key={advert.id} advert={advert} />
                ))}
            </AdvertWrapper>
        </div>
    );
};

export default AllAdverts;
