import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'lib/constants/keys';

class Token {
    public getToken(key: string) {
        switch (key) {
            case ACCESS_TOKEN_KEY:
                return this.getAccessTokenFromCookie();
            case REFRESH_TOKEN_KEY:
                return this.getRefreshTokenFromCookie();
            default:
                return null;
        }
    }

    public setToken(key: string, token: string) {
        switch (key) {
            case ACCESS_TOKEN_KEY:
                this.setAccessTokenInCookie(token);
                break;
            case REFRESH_TOKEN_KEY:
                this.setRefreshTokenInCookie(token);
                break;
        }
    }

    public removeToken(key: string) {
        switch (key) {
            case ACCESS_TOKEN_KEY:
                this.removeAccessTokenFromCookie();
                break;
            case REFRESH_TOKEN_KEY:
                this.removeRefreshTokenFromCookie();
                break;
        }
    }

    private getCookieOptions() {
        return 'samesite=lax; path=/';
    }

    private setAccessTokenInCookie(token: string) {
        document.cookie = `accessToken=${token}; ${this.getCookieOptions()}`;
    }

    private getAccessTokenFromCookie() {
        const name = 'accessToken=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookies = decodedCookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }
        return null;
    }

    private removeAccessTokenFromCookie() {
        document.cookie = `accessToken=; Max-Age=0; ${this.getCookieOptions()}`;
    }

    private setRefreshTokenInCookie(token: string) {
        document.cookie = `refreshToken=${token}; ${this.getCookieOptions()}`;
    }

    private getRefreshTokenFromCookie() {
        const name = 'refreshToken=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookies = decodedCookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }
        return null;
    }

    private removeRefreshTokenFromCookie() {
        document.cookie = `refreshToken=; Max-Age=0; ${this.getCookieOptions()}`;
    }
}

export default new Token();
