import { SelectUnderline } from 'components/common/custom-select/default';
import { InputRequired } from 'components/common/input-required';
import { RadioGroup } from 'components/common/radioGroup';

import {
    ownerOptions,
    propertyTypeOptions,
    advertTypeOptions,
} from 'pages/advert-create/constants';
import { CreateAdvertFormValues } from 'pages/advert-create/types';

import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
    form: UseFormReturn<CreateAdvertFormValues>;
};

const AboutAdvertStep = ({ form }: Props) => {
    const { t } = useTranslation();
    const { watch, setValue } = form;

    return (
        <div className={`px-4 md:px-6 lg:px-8 border-b-2 border-txt-40 duration-200`}>
            <div className="py-3 md:py-5 lg:py-7 flex justify-between items-center cursor-pointer">
                <h2 className="lg:text-3xl font-bold text-txt">{t('advert.about-advert')}</h2>
            </div>
            <div className="pb-2 md:pb-4">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-3 ">
                    <RadioGroup
                        selectedOption={watch('advertOwnerType')}
                        onChange={(value) => setValue('advertOwnerType', value)}
                        options={ownerOptions}
                        label={t('advert.whom')}
                        variant="primary"
                    />
                    <RadioGroup
                        selectedOption={watch('advertType')}
                        onChange={(value) => setValue('advertType', value)}
                        options={advertTypeOptions}
                        label={t('advert.purpose')}
                        variant="primary"
                    />
                    <div className="pt-2 max-w-sm">
                        <h5 className="md:text-lg text-base text-txt font-medium ">
                            {t('advert.property-type')}
                            <InputRequired />
                        </h5>
                        <div className="mb-5 md:mb-8 mt-2 md:mt-4 flex gap-3 flex-col sm:flex-row">
                            <SelectUnderline
                                options={propertyTypeOptions}
                                onChange={(value) => setValue('propertyType', value as number)}
                                value={watch('propertyType')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutAdvertStep;
