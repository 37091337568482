import { UseFormReturn } from 'react-hook-form';

import InputUnderline from 'components/common/input/underline';
import TextAreaUnderline from 'components/common/textarea/underline';
import { ReactComponent as PhotoIcon } from 'assets/icons/photo.svg';

import { useTranslation } from 'react-i18next';
import { CreateAdvertFormValues } from 'pages/advert-create/types';
import {
    buildingTypeOptions,
    documentStatusOptions,
    maintenanceOptions,
    mortgageStatusOptions,
    priceTypeOptions,
} from 'pages/advert-create/constants';
import { InputRequired } from 'components/common/input-required';
import { SelectUnderline } from 'components/common/custom-select/default';
import { ADVERT_TYPE, PROPERTY_TYPE } from 'lib/constants';
import Upload from 'components/common/upload';
import { RenderIf } from 'components/common/render-if';

type Props = {
    form: UseFormReturn<CreateAdvertFormValues>;
};

const AboutPropertyStep = ({ form }: Props) => {
    const { t } = useTranslation();
    const {
        watch,
        setValue,
        register,
        clearErrors,
        formState: { errors },
    } = form;

    const propertyType = watch('propertyType');
    const advertType = watch('advertType');

    const showMaintenance = [
        PROPERTY_TYPE.NEW_BUILDING,
        PROPERTY_TYPE.OLD_BUILDING,
        PROPERTY_TYPE.OFFICE,
        PROPERTY_TYPE.YARD_HOUSE,
        PROPERTY_TYPE.COMMERCIAL,
    ].includes(propertyType);
    const showRoomCount = [
        PROPERTY_TYPE.NEW_BUILDING,
        PROPERTY_TYPE.OLD_BUILDING,
        PROPERTY_TYPE.YARD_HOUSE,
        PROPERTY_TYPE.COMMERCIAL,
    ].includes(propertyType);
    const showFloorInfo = [
        PROPERTY_TYPE.NEW_BUILDING,
        PROPERTY_TYPE.OLD_BUILDING,
        PROPERTY_TYPE.COMMERCIAL,
    ].includes(propertyType);

    const showPropertyLandArea = propertyType === PROPERTY_TYPE.YARD_HOUSE;
    const showBuildingType = propertyType === PROPERTY_TYPE.COMMERCIAL;
    const showDocumentStatus = advertType === ADVERT_TYPE.SALE;
    const showMortgageStatus = advertType === ADVERT_TYPE.SALE;
    const showPriceType = advertType === ADVERT_TYPE.RENT;

    console.log(form.formState.errors);

    return (
        <div className={`px-4 md:px-6 lg:px-8 border-b-2 border-txt-40 duration-200`}>
            <div className="py-3 md:py-5 lg:py-7 flex justify-between items-center cursor-pointer">
                <h2 className="lg:text-3xl font-bold text-txt">{t('advert.about-property')}</h2>
            </div>
            <div className="pb-4 md:pb-8">
                <div className="grid grid-cols-1  md:grid-cols-3 lg:grid-cols-4 gap-x-8">
                    <RenderIf condition={showMaintenance}>
                        <div className="pt-2 max-w-sm">
                            <h5 className="md:text-lg text-base text-txt font-medium ">
                                {t('advert.maintenance-status')}
                                <InputRequired />
                            </h5>
                            <div className="mb-5 md:mb-8 mt-2 md:mt-4 flex gap-3 flex-col sm:flex-row">
                                <SelectUnderline
                                    options={maintenanceOptions}
                                    onChange={(value) =>
                                        setValue('maintenanceStatus', value as number)
                                    }
                                    value={watch('maintenanceStatus')}
                                />
                            </div>
                        </div>
                    </RenderIf>
                    <RenderIf condition={showBuildingType}>
                        <div className="pt-2 max-w-sm">
                            <h5 className="md:text-lg text-base text-txt font-medium ">
                                {t('advert.building-type')}
                                <InputRequired />
                            </h5>
                            <div className="mb-5 md:mb-8 mt-2 md:mt-4 flex gap-3 flex-col sm:flex-row">
                                <SelectUnderline
                                    options={buildingTypeOptions}
                                    onChange={(value) => setValue('buildingType', value as number)}
                                    value={watch('buildingType')}
                                />
                            </div>
                        </div>
                    </RenderIf>
                    <RenderIf condition={showDocumentStatus}>
                        <div className="pt-2 max-w-sm">
                            <h5 className="md:text-lg text-base text-txt font-medium ">
                                {t('advert.document-status')}
                                <InputRequired />
                            </h5>
                            <div className="mb-5 md:mb-8 mt-2 md:mt-4 flex gap-3 flex-col sm:flex-row">
                                <SelectUnderline
                                    options={documentStatusOptions}
                                    onChange={(value) =>
                                        setValue('documentStatus', value as number)
                                    }
                                    value={watch('documentStatus')}
                                />
                            </div>
                        </div>
                    </RenderIf>
                    <RenderIf condition={showMortgageStatus}>
                        <div className="pt-2 max-w-sm">
                            <h5 className="md:text-lg text-base text-txt font-medium ">
                                {t('advert.mortgage-status')}
                                <InputRequired />
                            </h5>
                            <div className="mb-5 md:mb-8 mt-2 md:mt-4 flex gap-3 flex-col sm:flex-row">
                                <SelectUnderline
                                    options={mortgageStatusOptions}
                                    onChange={(value) =>
                                        setValue('mortgageStatus', value as number)
                                    }
                                    value={watch('mortgageStatus')}
                                />
                            </div>
                        </div>
                    </RenderIf>

                    <div className="pt-2 max-w-sm">
                        <h5 className="md:text-lg text-base text-txt font-medium">
                            {t('advert.property-area')}
                            <InputRequired />
                        </h5>
                        <div className="mb-5 md:mb-8 mt-2 md:mt-4">
                            <InputUnderline
                                type="number"
                                {...register('propertyArea')}
                                error={errors.propertyArea?.message as string}
                                step={'0.01'}
                                rightText={
                                    propertyType === PROPERTY_TYPE.LAND ? (
                                        'sot'
                                    ) : (
                                        <span>
                                            m<sup>2</sup>
                                        </span>
                                    )
                                }
                            />
                        </div>
                    </div>
                    <RenderIf condition={showRoomCount}>
                        <div className="pt-2 max-w-sm">
                            <h5 className="md:text-lg text-base text-txt font-medium">
                                {t('advert.room-count')}
                                <InputRequired />
                            </h5>
                            <div className="mb-5 md:mb-8 mt-2 md:mt-4">
                                <InputUnderline
                                    type="number"
                                    {...register('roomCount')}
                                    error={errors.roomCount?.message as string}
                                    placeholder="1"
                                />
                            </div>
                        </div>
                    </RenderIf>
                    <RenderIf condition={showPropertyLandArea}>
                        <div className="pt-2 max-w-sm">
                            <h5 className="md:text-lg text-base text-txt font-medium">
                                {t('advert.property-land-area')}
                                <InputRequired />
                            </h5>
                            <div className="mb-5 md:mb-8 mt-2 md:mt-4">
                                <InputUnderline
                                    type="number"
                                    {...register('propertyArea')}
                                    error={errors.propertyArea?.message as string}
                                    step={'0.01'}
                                    rightText={'sot'}
                                />
                            </div>
                        </div>
                    </RenderIf>
                    <RenderIf condition={showFloorInfo}>
                        <>
                            <div className="pt-2 max-w-sm">
                                <h5 className="md:text-lg text-base text-txt font-medium">
                                    {t('advert.building-floor-count')}
                                    <InputRequired />
                                </h5>
                                <div className="mb-5 md:mb-8 mt-2 md:mt-4">
                                    <InputUnderline
                                        type="number"
                                        {...register('buildingFloorCount')}
                                        error={errors.buildingFloorCount?.message as string}
                                        placeholder="m^2"
                                    />
                                </div>
                            </div>
                            <div className="pt-2 max-w-sm">
                                <h5 className="md:text-lg text-base text-txt font-medium">
                                    {t('advert.property-floor')}
                                    <InputRequired />
                                </h5>
                                <div className="mb-5 md:mb-8 mt-2 md:mt-4">
                                    <InputUnderline
                                        type="number"
                                        {...register('propertyFloor')}
                                        error={errors.propertyFloor?.message as string}
                                        placeholder="1"
                                    />
                                </div>
                            </div>
                        </>
                    </RenderIf>
                    <div className="pt-2">
                        <h5 className="md:text-lg text-base text-txt font-medium ">
                            {t('advert.price')}
                            <InputRequired />
                        </h5>
                        <div className="mb-5 md:mb-8 mt-2 md:mt-4 flex gap-3 ">
                            <RenderIf condition={showPriceType}>
                                <div className="w-[120px] md:w-[170px]">
                                    <SelectUnderline
                                        options={priceTypeOptions}
                                        onChange={(value) => setValue('priceType', value as number)}
                                        value={watch('priceType')}
                                    />
                                </div>
                            </RenderIf>
                            <div className="w-[255px]">
                                <InputUnderline
                                    type="number"
                                    {...register('price')}
                                    error={errors.price?.message as string}
                                    placeholder="1"
                                    step={'0.01'}
                                    rightText="AZN"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="md:col-span-2 pt-2">
                        <h5 className="md:text-lg text-base text-txt font-medium">
                            {t('advert.description')}
                        </h5>
                        <div className="mb-5 md:mb-8 mt-2 md:mt-4 w-full">
                            <TextAreaUnderline
                                {...register('description')}
                                error={errors.description?.message as string}
                                placeholder={t('advert.enter-description')}
                                className="min-h-[120px] border resize-y w-full"
                            />
                        </div>
                    </div>

                    <div className="md:col-span-3 lg:col-span-4">
                        <h5 className="md:text-lg text-base text-txt font-medium">
                            Şəkil
                            <InputRequired />
                        </h5>
                        <div className=" flex items-center gap-x-10 my-3 md:my-5 py-2 md:py-3 px-4 md:px-8 bg-brend-05 rounded">
                            <PhotoIcon />
                            <div className="flex flex-col gap-y-1.5 md:gap-y-2">
                                <p>{t('advert.photo-rules-1')}</p>
                                <p>{t('advert.photo-rules-2')}</p>
                                <p>{t('advert.photo-rules-3')}</p>
                            </div>
                        </div>
                        <Upload
                            limit={18}
                            minSize={5}
                            type="photo"
                            onChange={(values) => {
                                setValue('images', values);
                                if (values.length > 4) {
                                    clearErrors('images');
                                }
                            }}
                            error={errors.images?.message as string}
                        />
                    </div>
                    <div className="md:col-span-3 lg:col-span-4">
                        <h5 className="md:text-lg text-base text-txt font-medium">Video</h5>
                        <div className=" flex items-center gap-x-10 my-3 md:my-5 py-2 md:py-4 px-4 md:px-8 bg-brend-05 rounded">
                            <PhotoIcon />
                            <div className="flex flex-col gap-y-1.5 md:gap-y-2">
                                <p>{t('advert.video-rules-1')}</p>
                                <p>{t('advert.video-rules-2')}</p>
                            </div>
                        </div>
                        <Upload
                            limit={5}
                            type="video"
                            onChange={(values) => {
                                setValue('videos', values);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutPropertyStep;
