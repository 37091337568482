import { createBrowserRouter } from 'react-router-dom';
import Root from 'layout/root';
import { ComponentType, ReactNode, Suspense, lazy } from 'react';
import { paths } from 'lib/constants/paths';
import AuthGuard from 'layout/auth-guard';
import HomePage from 'pages/home';
import { LoadingOverlay } from 'components/common/loading-overlay';

// Lazy load the pages
const AdvertPage = lazy(() => import('pages/advert'));
const CreateAdvertPage = lazy(() => import('pages/advert-create'));
const AgenciesPage = lazy(() => import('pages/agencies'));
const AgencyPage = lazy(() => import('pages/agency'));
const CabinetMainPage = lazy(() => import('pages/cabinet/main'));
const CabinetProfilePage = lazy(() => import('pages/cabinet/profile'));
const CabinetAgencyPage = lazy(() => import('pages/cabinet/agency'));
const CabinetAdvertPage = lazy(() => import('pages/cabinet/advert'));
const CabinetBookmarkPage = lazy(() => import('pages/cabinet/bookmark'));
const CabinetPlanPage = lazy(() => import('pages/cabinet/plan'));
const CabinetBillingPage = lazy(() => import('pages/cabinet/billing'));
const ResidentialComplexPage = lazy(() => import('pages/residential-complex'));
const ResidentialComplexesPage = lazy(() => import('pages/residential-complexes'));

const Loading = () => <LoadingOverlay fullScreen />;

const routes = [
    {
        path: paths.home,
        element: HomePage,
        showFooter: true,
        hasSuspense: false,
        requireAuth: false,
    },
    {
        path: paths.cabinet.main,
        element: CabinetMainPage,
        showFooter: true,
        hasSuspense: true,
        requireAuth: true,
    },
    {
        path: paths.cabinet.profile,
        element: CabinetProfilePage,
        showFooter: true,
        hasSuspense: true,
        requireAuth: true,
    },
    {
        path: paths.cabinet.agency,
        element: CabinetAgencyPage,
        showFooter: true,
        hasSuspense: true,
        requireAuth: true,
    },
    {
        path: paths.cabinet.advert,
        element: CabinetAdvertPage,
        showFooter: true,
        hasSuspense: true,
        requireAuth: true,
    },
    {
        path: paths.cabinet.bookmark,
        element: CabinetBookmarkPage,
        showFooter: true,
        hasSuspense: true,
        requireAuth: true,
    },
    {
        path: paths.cabinet.plan,
        element: CabinetPlanPage,
        showFooter: true,
        hasSuspense: true,
        requireAuth: true,
    },
    {
        path: paths.cabinet.billing,
        element: CabinetBillingPage,
        showFooter: true,
        hasSuspense: true,
        requireAuth: true,
    },
    {
        path: paths.advert.create,
        element: CreateAdvertPage,
        showFooter: true,
        hasSuspense: true,
        requireAuth: false,
    },
    {
        path: paths.advert.view(),
        element: AdvertPage,
        showFooter: true,
        hasSuspense: true,
        requireAuth: false,
    },
    {
        path: paths.agencies,
        element: AgenciesPage,
        showFooter: true,
        hasSuspense: true,
        requireAuth: false,
    },
    {
        path: paths.agency(),
        element: AgencyPage,
        showFooter: true,
        hasSuspense: true,
        requireAuth: false,
    },
    {
        path: paths.residentialComplexes.list,
        element: ResidentialComplexesPage,
        showFooter: true,
        hasSuspense: true,
        requireAuth: false,
    },
    {
        path: paths.residentialComplexes.view(),
        element: ResidentialComplexPage,
        showFooter: true,
        hasSuspense: true,
        requireAuth: false,
    },
];

const wrapWithRoot = (
    Component: ComponentType,
    showFooter: boolean,
    hasSuspense: boolean,
    requireAuth: boolean,
): ReactNode => {
    const content = hasSuspense ? (
        <Suspense fallback={<Loading />}>
            <Component />
        </Suspense>
    ) : (
        <Component />
    );

    return (
        <AuthGuard requireAuth={requireAuth}>
            <Root showFooter={showFooter}>{content}</Root>
        </AuthGuard>
    );
};

export const router = createBrowserRouter(
    routes.map(({ path, element, showFooter, hasSuspense, requireAuth }) => ({
        path,
        element: wrapWithRoot(element, showFooter, hasSuspense, requireAuth),
    })),
);
