import ButtonOutline from 'components/common/button/outline';
import InputUnderline from 'components/common/input/underline';
import { Link } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { PASSWORD_REGEX, PHONE_NUMBER_REGEX } from 'lib/constants';
import { usePostApiV1ClientIdentityRegisterMutation } from 'service/api/allApis';
import { toast } from 'sonner';
import { PhoneInput } from 'components/common/phone-input';

interface LoginProps {
    onLoginOpen: () => void;
}

type FormValues = {
    name: string;
    surname: string;
    email: string;
    phoneNumber: string;
    password: string;
    confirmedPassword: string;
};

const getSchema = (t: TFunction<'translation', undefined>) =>
    yup
        .object({
            name: yup.string().required(t('validation.field-required', { field: t('auth.name') })),
            surname: yup
                .string()
                .required(t('validation.field-required', { field: t('auth.surname') })),
            email: yup
                .string()
                .email(t('validation.invalid-field', { field: t('auth.email') }))
                .required(t('validation.field-required', { field: t('auth.email') })),
            phoneNumber: yup
                .string()
                .required(t('validation.field-required', { field: t('auth.phone-number') }))
                .matches(
                    PHONE_NUMBER_REGEX,
                    t('validation.invalid-field', { field: t('auth.phone-number') }),
                ),
            password: yup
                .string()
                .required(t('validation.field-required', { field: t('auth.password') }))
                .matches(PASSWORD_REGEX, t('auth.invalid-password')),
            confirmedPassword: yup
                .string()
                .required(t('validation.field-required', { field: t('auth.confirm-password') }))
                .oneOf([yup.ref('password'), ''], t('auth.passwords-dont-match')),
        })
        .required();

const Register = ({ onLoginOpen }: LoginProps) => {
    const [registerMutation, { isLoading }] = usePostApiV1ClientIdentityRegisterMutation();
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: yupResolver(getSchema(t)),
    });

    const onSubmit: SubmitHandler<FormValues> = (values) => {
        registerMutation({
            darxApplicationDtOsIdentityDtOsRegisterDto: {
                email: values.email,
                password: values.password,
                name: values.name,
                surname: values.surname,
                phoneNumber: values.phoneNumber,
            },
        })
            .unwrap()
            .then(() => {
                toast.success(t('auth.register-success'));
                onLoginOpen();
            })
            .catch((e) => {
                const errorMessage = e?.data?.Title ?? t('general.something-went-wrong');
                toast.error(errorMessage);
            });
    };

    return (
        <div className="w-[360px] flex flex-col items-center border border-dark-20 py-[30px] px-10">
            <h2 className="text-[32px] font-bold text-txt">{t('auth.register')}</h2>
            <div className="mt-5 mb-8 flex items-center gap-x-3">
                <p className="text-sm text-txt-60 font-medium">
                    {t('auth.already-have-an-account')}
                </p>
                <button
                    onClick={onLoginOpen}
                    className="text-brend text-sm font-semibold underline hover:text-primary cursor-pointer"
                >
                    {t('auth.login')}
                </button>
            </div>
            <form
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="on"
                className="w-full flex flex-col gap-y-4"
            >
                <InputUnderline
                    autoComplete="name"
                    placeholder={t('auth.name')}
                    {...register('name')}
                    error={errors.name?.message}
                />
                <InputUnderline
                    type="text"
                    autoComplete="family-name"
                    placeholder={t('auth.surname')}
                    {...register('surname')}
                    error={errors.surname?.message}
                />
                <div>
                    <PhoneInput
                        defaultCountry="AZ"
                        international
                        placeholder="Enter a phone number"
                        onChange={(value) => {
                            setValue('phoneNumber', value);
                        }}
                    />
                    <p className="text-red-600 text-xs h-4 mt-1">{errors.phoneNumber?.message}</p>
                </div>
                <InputUnderline
                    type="text"
                    autoComplete="email"
                    placeholder={t('auth.email')}
                    {...register('email')}
                    error={errors.email?.message}
                />
                <InputUnderline
                    type="password"
                    autoComplete="new-password"
                    placeholder={t('auth.password')}
                    {...register('password')}
                    error={errors.password?.message}
                />
                <InputUnderline
                    type="password"
                    autoComplete="new-password"
                    placeholder={t('auth.confirm-password')}
                    {...register('confirmedPassword')}
                    error={errors.confirmedPassword?.message}
                />
                <ButtonOutline
                    loading={isLoading}
                    text={t('auth.register')}
                    className="rounded-sm text-txt-60 disabled:hover:text-txt-60 border border-txt-60"
                />
                <p className="text-txt-60 text-xs font-medium -mt-2">
                    {t('auth.aggree-to-the-terms-of-use-1')}{' '}
                    <Link to={'/'} className="underline hover:text-dark duration-75">
                        {t('auth.aggree-to-the-terms-of-use-2')}
                    </Link>{' '}
                    {t('auth.aggree-to-the-terms-of-use-3')}
                </p>
            </form>
        </div>
    );
};

export default Register;
