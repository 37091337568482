import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { cn } from 'lib/utils';
import React, { useEffect, useState } from 'react';
import { IOption } from 'types';

interface CustomSelectSecondaryProps {
    selectedOption: IOption;
    options: IOption[];
    onChange: (option: IOption) => void;
}

const CustomSelectSecondary = ({
    selectedOption,
    options,
    onChange,
}: CustomSelectSecondaryProps) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const onOutsideClick = () => {
            setIsOpen(false);
        };

        window.addEventListener('click', onOutsideClick);

        return () => window.removeEventListener('click', onOutsideClick);
    }, []);
    return (
        <div
            onClick={(e) => e.stopPropagation()}
            className="relative cursor-pointer text-lg font-medium"
        >
            <div
                onClick={() => setIsOpen((prev) => !prev)}
                className={cn(
                    'min-w-[140px] flex gap-x-2.5 justify-between items-center px-2.5 py-2  bg-brend-20',
                    isOpen ? 'rounded-t-2xl' : 'rounded-2xl',
                )}
            >
                {selectedOption.label}
                <ArrowDownIcon
                    fill="black"
                    className={cn('duration-150', isOpen && 'rotate-180')}
                />
            </div>
            {isOpen && (
                <div className="absolute w-full bg-brend-20 top-9 left-0 rounded-b-2xl">
                    {options.map(
                        (option) =>
                            option.value !== selectedOption.value && (
                                <div
                                    key={option.value}
                                    onClick={() => {
                                        setIsOpen(false);
                                        onChange(option);
                                    }}
                                    className="px-2.5 py-2 cursor-pointer rounded-b-2xl duration-100 hover:bg-primary hover:text-white"
                                >
                                    {option.label}
                                </div>
                            ),
                    )}
                </div>
            )}
        </div>
    );
};

export default CustomSelectSecondary;
