import { RootState } from 'store';
import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { logout, setAccessToken } from 'store/features/userSlice'; // assuming you have actions for logout and setting new token
import token from 'lib/token';
import { REFRESH_TOKEN_KEY } from 'lib/constants/keys';
import { TTokenResponse } from 'types';

const baseUrl = process.env.REACT_APP_API_URL;

// Create a new mutex to avoid race conditions
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).userState.token;
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

const customFetchBaseQuery = async (args: any, api: any, extraOptions: any) => {
    // Wait until the mutex is available without locking it
    await mutex.waitForUnlock();

    // First attempt to make the request
    let result = await baseQuery(args, api, extraOptions);
    const refreshToken = token.getToken(REFRESH_TOKEN_KEY);

    // If we get a 401 error (unauthorized), try refreshing the token
    if (result.error && (result.error as FetchBaseQueryError).status === 401 && refreshToken) {
        // Check if the mutex is locked
        if (!mutex.isLocked()) {
            // Lock the mutex
            const release = await mutex.acquire();

            try {
                const refreshResult = await baseQuery(
                    {
                        url: `/api/v1/client/Auth/RefreshTokenLoginAsync?refreshToken=${encodeURIComponent(
                            refreshToken,
                        )}`,
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${refreshToken}`,
                        },
                    },
                    api,
                    extraOptions,
                );

                if (refreshResult.data) {
                    // Store the new token
                    const data = refreshResult.data as TTokenResponse;
                    const accessToken = data.accessToken;
                    const refreshToken = data.refreshToken;
                    api.dispatch(setAccessToken(accessToken));
                    token.setToken(REFRESH_TOKEN_KEY, refreshToken);
                    // Retry the original request with the new token
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    // If refresh fails, log the user out
                    api.dispatch(logout());
                }
            } finally {
                // Release the mutex once done
                release();
            }
        } else {
            // If the mutex is locked, wait for it to be unlocked and retry the request
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }

    return result;
};

export const baseApi = createApi({
    baseQuery: customFetchBaseQuery,
    endpoints: () => ({}),
});
