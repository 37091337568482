import { RenderIf } from 'components/common/render-if';
import { Spinner } from 'components/common/spinner';
import { cn } from 'lib/utils';
import React from 'react';

interface ButtonOutlineProps {
    text: React.ReactNode;
    disabled?: boolean;
    onClick?: () => void;
    className?: string;
    loading?: boolean;
}
const ButtonOutline = ({ text, disabled, loading, onClick, className }: ButtonOutlineProps) => {
    return (
        <button
            disabled={disabled || loading}
            onClick={onClick}
            className={cn(
                'flex items-center justify-center gap-2 px-[20px] py-2 border-2 border-primary rounded-[40px] text-brend font-medium tracking-[0.64px] disabled:opacity-80 hover:bg-primary hover:text-white disabled:hover:bg-transparent disabled:hover:text-primary duration-150 cursor-pointer',
                className,
            )}
        >
            <RenderIf condition={!!loading}>
                <Spinner size={14} color="#B1924C" />
            </RenderIf>
            {text}
        </button>
    );
};

export default ButtonOutline;
