import advertTempImg from 'assets/images/temp-4.png';
import Advert from 'components/common/advert-card';
import { t } from 'i18next';
import AdvertWrapper from 'layout/advert-wrapper';
import React from 'react';

const ADVERTS = [
    {
        id: 1,
        title: 'Port Baku Residence',
        address: 'Xanlar küçəsi',
        price: '205 000 AZN',
        roomCount: 5,
        volume: '75 m²',
        floor: '13/14',
        repaired: true,
        createdAt: 'Bu gün 11:00',
        type: 'sale',
        hasMortgage: true,
        img: advertTempImg,
    },
    {
        id: 2,
        title: 'Port Baku Residence',
        address: 'Xanlar küçəsi',
        price: '205 000 AZN',
        roomCount: 5,
        volume: '75 m²',
        floor: '13/14',
        repaired: true,
        createdAt: 'Bu gün 11:00',
        type: 'sale',
        hasMortgage: true,
        img: advertTempImg,
    },
    {
        id: 3,
        title: 'Port Baku Residence',
        address: 'Xanlar küçəsi',
        price: '205 000 AZN',
        roomCount: 5,
        volume: '75 m²',
        floor: '13/14',
        repaired: true,
        createdAt: 'Bu gün 11:00',
        type: 'sale',
        hasMortgage: true,
        img: advertTempImg,
    },
    {
        id: 4,
        title: 'Port Baku Residence',
        address: 'Xanlar küçəsi',
        price: '205 000 AZN',
        roomCount: 5,
        volume: '75 m²',
        floor: '13/14',
        repaired: true,
        createdAt: 'Bu gün 11:00',
        type: 'sale',
        hasMortgage: true,
        img: advertTempImg,
    },
];

const VipAdverts = () => {
    return (
        <div className="mt-10 md:mt-0 mb-6 md:mb-10 lg:mb-20 w-full px-5 md:px-0 md:w-[925px] lg:w-[1240px] mx-auto">
            <h2 className=" md:text-brend text-center text-[32px] leading-[44px] font-bold tracking-[3.84px] mb-6">
                {t('general.vip-adverts')}
            </h2>
            <AdvertWrapper>
                {/* {ADVERTS.map((advert) => (
                    <Advert key={advert.id} advert={advert} />
                ))} */}
            </AdvertWrapper>
        </div>
    );
};

export default VipAdverts;
