import { cn } from 'lib/utils';
import React from 'react';

interface AdvertBadgeProps {
    title: string;
    backgroundColor: string;
    containerClassName: string;
}

const AdvertBadge = ({ title, backgroundColor, containerClassName }: AdvertBadgeProps) => {
    return (
        <div className={cn('flex absolute h-[12px] md:h-[21px]', containerClassName)}>
            <span
                className="inline-block pl-[5px] md:pl-2 pt-[0.2px] md:pt-0.5 pr-[1px] text-[7px] md:text-xs text-white font-bold"
                style={{ backgroundColor }}
            >
                {title}
            </span>
            <span
                className="ml-[-0.25px] w-[5.5px] md:w-[9px] h-[12px] md:h-[21px] inline-block"
                style={{ backgroundColor, clipPath: 'polygon(100% 50%, 0 0, 0 100%)' }}
            />
        </div>
    );
};

export default AdvertBadge;
