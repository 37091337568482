import { ReactComponent as FacebookIcon } from 'assets/icons/facebook-bg.svg';
import React from 'react';

const FacebookButton = () => {
    return (
        <button className="bg-[#1A77F2] w-full rounded-2xl h-[42px] flex justify-center gap-x-3 text-white items-center hover:bg-transparent border-2 border-transparent hover:border-[#1A77F2] hover:text-[#1A77F2] group duration-100 font-medium cursor-pointer">
            <FacebookIcon className="group-hover:fill-[#1A77F2]" />
            Facebook
        </button>
    );
};

export default FacebookButton;
