import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { AnchorProps } from '@headlessui/react/dist/internal/floating';
import { useTranslation } from 'react-i18next';
import { PiUserCircleLight } from 'react-icons/pi';
import { CiLogout } from 'react-icons/ci';
import { useDispatch } from 'react-redux';
import { logout } from 'store/features/userSlice';
import { Link } from 'react-router-dom';
import { paths } from 'lib/constants/paths';

export function UserButton() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    return (
        <Menu>
            <MenuButton>
                <PiUserCircleLight size={50} color="#b1924c" />
            </MenuButton>
            <MenuItems
                anchor={'bottom end' as AnchorProps}
                className="mt-2 z-30 min-w-[180px] overflow-auto rounded-lg border border-slate-200 bg-white p-1.5 shadow-sm focus:outline-none"
            >
                <MenuItem>
                    <Link
                        to={paths.cabinet.main}
                        className="cursor-pointer  flex w-full text-sm items-center rounded-md p-3 transition-all hover:bg-brend/10 focus:bg-brend/10 active:bg-brend/20"
                    >
                        <PiUserCircleLight size={24} color="#b1924c" />
                        <p className="text-txt-80 font-medium ml-2">{t('general.profile')}</p>
                    </Link>
                </MenuItem>
                <MenuItem>
                    <div
                        onClick={() => {
                            dispatch(logout());
                        }}
                        className="cursor-pointer  flex w-full text-sm items-center rounded-md p-3 transition-all hover:bg-brend/10 focus:bg-brend/10 active:bg-brend/20"
                    >
                        <CiLogout size={24} color="#b1924c" />
                        <p className="text-txt-80 font-medium ml-2">{t('general.logout')}</p>
                    </div>
                </MenuItem>
            </MenuItems>
        </Menu>
    );
}
