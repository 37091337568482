export const PASSWORD_REGEX = new RegExp(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
);
export const PHONE_NUMBER_REGEX = new RegExp(
    /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
);

export enum ADVERT_OWNER_TYPE {
    OWNER = 0,
    AGENCY = 1,
}
export enum ADVERT_TYPE {
    RENT = 0,
    SALE = 1,
}

export enum PROPERTY_TYPE {
    NEW_BUILDING = 0,
    OLD_BUILDING = 1,
    YARD_HOUSE = 2,
    OFFICE = 3,
    GARAGE = 4,
    LAND = 5,
    COMMERCIAL = 6,
}

export enum BUILDING_TYPE {
    BUSINNESS_CENTER = 0,
    HOME = 1,
    VILLA = 2,
}

export enum MAINTENANCE_STATUS {
    FULL = 0,
    AVERAGE = 1,
    BAD = 2,
}

export enum DOCUMENT_STATUS {
    YES = 0,
    NO = 1,
}

export enum MORTGAGE_STATUS {
    YES = 0,
    NO = 1,
}

export enum PRICE_TYPE {
    MONTHLY = 0,
    DAILY = 1,
}

export const FILTER_OPTIONS = {
    checkboxItems: [
        {
            value: 'bir-olmasin',
            label: 'Bir olmasın',
        },
        {
            value: 'axrinci-olmasin',
            label: 'Axrıncı olmasın',
        },
        {
            value: 'yalniz-en-ust',
            label: 'Yalnız ən üst',
        },
        {
            value: 'temirli',
            label: 'Təmirli',
        },
        {
            value: 'cixaris-var',
            label: 'Çıxarış var',
        },
        {
            value: 'ipoteka',
            label: 'İpoteka',
        },
    ],
    category: [
        {
            value: 'rent',
            label: 'Kirayə',
        },
        {
            value: 'sale',
            label: 'Satış',
        },
    ],
};

export const USER_TYPES = [
    { value: 'agent', label: 'Aqentlik' },
    { value: 'user', label: 'Mülkiyyətçi' },
];
