import advertisementTempImg from 'assets/images/temp-5.png';
import Modal from 'components/common/modal';
import Login from 'components/login';
import { useModal } from 'hooks/use-modal';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const LoginModal = () => {
    const { isOpen, type, onClose, onOpen } = useModal();
    const [searchParams, setSearchParams] = useSearchParams();
    const requireAuth = !!searchParams.get('require-auth');
    const isModalOpen = isOpen && type == 'login';

    useEffect(() => {
        if (window.innerWidth >= 925 && requireAuth) {
            onOpen('login');
            searchParams.delete('require-auth');
            setSearchParams(searchParams);
        }
    }, [requireAuth]);

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={onClose}
            modalContainerClassName="hidden md:flex"
            modalClassName="md:w-[925px] lg:w-[1080px] py-[50px] md:px-[50px] lg:px-[100px] bg-white flex justify-between items-center rounded-[16px]"
        >
            <Login onRegisterOpen={() => onOpen('register')} onLoginClose={onClose} />
            <img
                src={advertisementTempImg}
                alt="advertisement"
                className="md:w-[350px] lg:w-[400px] md:h-[400px] lg:h-[459px] object-cover"
            />
        </Modal>
    );
};

export default LoginModal;
