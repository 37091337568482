import React, { createContext, useContext, useEffect, useState } from 'react';
import { ISidebarContextType } from 'types';

const SidebarContext = createContext<ISidebarContextType | null>(null);

const SidebarProvider = ({ children }: { children: React.ReactNode }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const showSidebar = (e?: React.MouseEvent) => {
        e?.stopPropagation();
        setIsSidebarOpen(true);
    };
    const closeSidebar = () => {
        setIsSidebarOpen(false);
    };

    useEffect(() => {
        const onWindowClick = () => {
            closeSidebar();
        };
        window.addEventListener('click', onWindowClick);

        return () => window.removeEventListener('click', onWindowClick);
    }, []);

    return (
        <SidebarContext.Provider value={{ isSidebarOpen, showSidebar, closeSidebar }}>
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebarContext = () => {
    return useContext(SidebarContext);
};

export { SidebarProvider };
