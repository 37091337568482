import { ReactComponent as BurgerOutlineIcon } from 'assets/icons/burger-outline.svg';
import CustomSelectUnderline from 'components/common/custom-select/underline';
import { useSidebarContext } from 'contexts/sidebar';
import { cn } from 'lib/utils';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ISidebarContextType } from 'types';

const Sidebar = () => {
    const { isSidebarOpen, closeSidebar } = useSidebarContext() as ISidebarContextType;
    const [selectedCountry, setSelectedCountry] = useState({
        value: 'azerbaijan',
        label: 'Azərbaycan',
    });

    return (
        <div
            className={cn(
                'lg:hidden fixed top-0 w-[50vw] min-w-[320px] max-w-[440px] h-screen bg-white overflow-auto pt-[70px] pb-10 px-9 z-40',
                isSidebarOpen ? 'right-0' : '-right-[100%]',
            )}
            style={{
                transition: '0.5s right',
            }}
            onClick={(e) => e.stopPropagation()}
        >
            <BurgerOutlineIcon
                className="lg:hidden cursor-pointer ml-auto"
                onClick={closeSidebar}
            />
            <div className="mt-[70px]">
                <CustomSelectUnderline
                    selectedOption={selectedCountry}
                    options={[
                        { value: 'azerbaijan', label: 'Azərbaycan' },
                        { value: 'turkey', label: 'Türkiyə' },
                    ]}
                    onChange={(option) => setSelectedCountry(option)}
                />
                <Link
                    to={'/agencies'}
                    className="py-2 my-2 ml-[29px] w-full block text-txt text-[20px] md:text-[24px] font-medium"
                >
                    Agentliklər
                </Link>
                <div className="w-full h-[1px] bg-dark-60" />
                <Link
                    to={'/residential-complexes'}
                    className="py-2 my-2 ml-[29px] w-full block text-txt text-[20px] md:text-[24px] font-medium"
                >
                    Yaşayış kompleksləri
                </Link>
                <div className="w-full h-[1px] bg-dark-60" />
                <Link
                    to={'/agents'}
                    className="py-2 my-2 ml-[29px] w-full block text-txt text-[20px] md:text-[24px] font-medium"
                >
                    Xidmətlər
                </Link>
                <div className="w-full h-[1px] bg-dark-60" />
                <Link
                    to={'/agents'}
                    className="py-2 my-2 ml-[29px] w-full block text-txt text-[20px] md:text-[24px] font-medium"
                >
                    Blog
                </Link>
                <div className="w-full h-[1px] bg-dark-60" />
                <Link
                    to={'/agents'}
                    className="py-2 my-2 ml-[29px] w-full block text-txt text-[20px] md:text-[24px] font-medium"
                >
                    Xəbərlər
                </Link>
                <div className="w-full h-[1px] bg-dark-60" />
            </div>
        </div>
    );
};

export default Sidebar;
