import { cn } from 'lib/utils';
import React from 'react';

interface IHashtagProps {
    label: string;
    className?: string;
}
const Hashtag = ({ label, className }: IHashtagProps) => {
    return (
        <h3 className={cn('text-brend font-bold text-base uppercase tracking-[1.92px]', className)}>
            #{label}
        </h3>
    );
};

export default Hashtag;
