import ChangeLanguage from 'components/common/change-language';
import CustomSelectOutline from 'components/common/custom-select/outline';
import React, { useState } from 'react';
import { FiPhone } from 'react-icons/fi';
import { IoMailOpenOutline } from 'react-icons/io5';

const Header = () => {
    const [selectedCurrency, setSelectedCurrency] = useState({ label: 'AZN', value: 'azn' });

    return (
        <header className="relative z-50 hidden md:flex md:px-[37px] lg:px-40 h-9 items-center justify-between bg-primary text-white text-xs">
            {/* <div className="flex">
                <Link
                    to={'/'}
                    className="px-2.5 h-[25px] grid place-items-center rounded border border-white mr-8 hover:bg-white hover:text-primary duration-100"
                >
                    {t('general.rent-a-car')}
                </Link>
                <Link
                    to={'/'}
                    className="px-2.5 h-[25px] grid place-items-center rounded border border-white hover:bg-white hover:text-primary duration-100"
                >
                    {t('general.cleaning-company')}
                </Link>
            </div> */}
            <div className="flex">
                <p className="flex items-center gap-x-2 w-[180px]">
                    <FiPhone />
                    <a href="tel:994(55) 555-55-55">994(55) 555-55-55</a>
                </p>
                <p className="flex items-center gap-x-2 w-[180px]">
                    <FiPhone />
                    <a href="tel:994(55) 555-55-55">994(55) 555-55-55</a>
                </p>
                <p className="flex items-center justify-end gap-x-2 w-[100px]">
                    <IoMailOpenOutline className="w-4 h-4" />
                    <a href="mailto:info@darx.az">info@darx.az</a>
                </p>
            </div>
            <div className="flex items-center md:gap-x-8 ">
                <CustomSelectOutline
                    selectedOption={selectedCurrency}
                    onChange={(option) => setSelectedCurrency(option)}
                    options={[
                        { label: 'AZN', value: 'azn' },
                        { label: 'EUR', value: 'euro' },
                        { label: 'USD', value: 'usd' },
                    ]}
                    dropDownClassName="bg-brend border-white"
                />
                <ChangeLanguage />
            </div>
        </header>
    );
};

export default Header;
