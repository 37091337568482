import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '../locales/en.json';
import az from '../locales/az.json';
import ru from '../locales/ru.json';

const defaultLanguage = localStorage.getItem('lng') ?? 'en-US';

export const defaultNamespace = 'default';

export const onLanguageChange = (lng: string) => {
    localStorage.setItem('lng', lng);
    i18n.changeLanguage(lng);
};

export const resources = {
    'az-AZ': {
        [defaultNamespace]: az,
    },
    'en-US': {
        [defaultNamespace]: en,
    },
    'ru-RU': {
        [defaultNamespace]: ru,
    },
};

i18n.use(initReactI18next).init({
    defaultNS: defaultNamespace,
    ns: [defaultNamespace],
    resources,
    lng: defaultLanguage,
    fallbackLng: defaultLanguage,
    interpolation: {
        escapeValue: false,
    },
});
