import Checkbox from 'components/common/checkbox';
import { cn } from 'lib/utils';
import React, { useState } from 'react';
import { IOption } from 'types';

interface CheckOptionProps {
    option: IOption & {
        type: 'checkbox' | 'simple';
    };
}

const CheckOption = ({ option }: CheckOptionProps) => {
    const [checked, setChecked] = useState(false);
    if (option.type === 'checkbox') {
        return (
            <div
                onClick={() => setChecked((prev) => !prev)}
                className="group w-full flex items-center justify-center gap-x-2 lg:justify-between py-3 lg:px-5 border-[0.5px] border-dark rounded cursor-pointer"
            >
                <span className="font-medium text-txt text-sm md:text-base">{option.label}</span>
                <Checkbox value={checked} onChange={() => setChecked((prev) => !prev)} size={14} />
            </div>
        );
    }

    return (
        <div
            onClick={() => setChecked((prev) => !prev)}
            className={cn(
                'whitespace-nowrap text-xs md:text-inherit w-full py-3 md:px-5 border-[0.5px] border-brend rounded cursor-pointer font-medium text-center',
                checked ? 'text-white bg-brend' : 'text-brend bg-brend-05 md:hover:bg-brend-20',
            )}
        >
            {option.label}
        </div>
    );
};

export default CheckOption;
