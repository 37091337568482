import 'swiper/css';
import 'swiper/css/pagination';

import { ReactComponent as FloorIcon } from 'assets/icons/floor.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { ReactComponent as VolumeIcon } from 'assets/icons/volume.svg';
import tempImg1Src from 'assets/images/temp-1.png';
import tempImg2Src from 'assets/images/temp-2.png';
import tempImg3Src from 'assets/images/temp-3.png';
import Hashtag from 'components/common/hashtag';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';

const ADVERTS = [
    {
        id: 1,
        type: 'sale',
        title: 'Port Baku Residence',
        description:
            'Port Baku Residence Сдаётся 2х комнатная комфортная квартира . Квартира с хорошим ремонтом и интерьером. Чисто, комфортно, уютно!!! Быстрое поселение и оформление документов!',
        images: [tempImg1Src, tempImg2Src, tempImg3Src],
    },
    {
        id: 2,
        type: 'rent',
        title: 'Port Baku Residence',
        description:
            'Port Baku Residence Сдаётся 2х комнатная комфортная квартира . Квартира с хорошим ремонтом и интерьером. Чисто, комфортно, уютно!!! Быстрое поселение и оформление документов!',
        images: [tempImg1Src, tempImg2Src, tempImg3Src],
    },
    {
        id: 3,
        type: 'sale',
        title: 'Port Baku Residence',
        description:
            'Port Baku Residence Сдаётся 2х комнатная комфортная квартира . Квартира с хорошим ремонтом и интерьером. Чисто, комфортно, уютно!!! Быстрое поселение и оформление документов!',
        images: [tempImg1Src, tempImg2Src, tempImg3Src],
    },
    {
        id: 4,
        type: 'sale',
        title: 'Port Baku Residence',
        description:
            'Port Baku Residence Сдаётся 2х комнатная комфортная квартира . Квартира с хорошим ремонтом и интерьером. Чисто, комфортно, уютно!!! Быстрое поселение и оформление документов!',
        images: [tempImg1Src, tempImg2Src, tempImg3Src],
    },
    {
        id: 5,
        type: 'sale',
        title: 'Port Baku Residence',
        description:
            'Port Baku Residence Сдаётся 2х комнатная комфортная квартира . Квартира с хорошим ремонтом и интерьером. Чисто, комфортно, уютно!!! Быстрое поселение и оформление документов!',
        images: [tempImg1Src, tempImg2Src, tempImg3Src],
    },
];

const PremiumAdvertSlider = () => {
    const { t } = useTranslation();

    return (
        <section className="hidden md:block mb-[50px] z-[1] relative">
            <h2 className="text-brend text-center text-[32px] leading-[44px] font-bold tracking-[3.84px]">
                {t('general.premium-adverts')}
            </h2>
            <Swiper
                loop={true}
                pagination={{
                    clickable: true,
                }}
                speed={2000}
                modules={[Pagination, Autoplay]}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                className="md:w-[925px] lg:w-[1070px] mx-auto md:h-[500px] lg:h-[520px] mt-10 py-10"
            >
                {ADVERTS.map((advert) => (
                    <SwiperSlide key={advert.id}>
                        <div className="flex md:gap-x-5 lg:gap-x-[62px]">
                            <div className="md:w-[525px] lg:w-[544px] pl-5">
                                <Hashtag label={t(`general.${advert.type}`)} />
                                <h2 className="text-txt text-[28px] font-bold my-4">
                                    {advert.title}
                                </h2>
                                <p className="text-txt-40 text-sm font-semibold mb-6">
                                    {advert.description}
                                </p>
                                <div
                                    className="w-[335px] h-[100px] flex items-center gap-x-[18px] pl-6 rounded-2xl"
                                    style={{
                                        boxShadow: '2px 6px 20px 0px rgba(0, 0, 0, 0.15)',
                                    }}
                                >
                                    <HomeIcon />
                                    <div>
                                        <p className="text-brend font-bold text-base tracking-[0.64px]">
                                            4 otaqlı mənzil
                                        </p>
                                        <div className="flex gap-x-6 mt-2.5 text-txt-40 text-xs font-semibold">
                                            <p className="flex gap-x-1.5 items-center">
                                                <VolumeIcon /> 120 m2
                                            </p>
                                            <p className="flex gap-x-1.5 items-center">
                                                <FloorIcon /> 5/16 mərtəbə
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="w-[335px] h-[100px] mt-4 flex items-center gap-x-[18px] pl-6 rounded-2xl"
                                    style={{
                                        boxShadow: '2px 6px 20px 0px rgba(0, 0, 0, 0.15)',
                                    }}
                                >
                                    <LocationIcon />
                                    <div>
                                        <p className="text-brend font-bold text-base tracking-[0.64px]">
                                            Nərimanov rayonu
                                        </p>
                                        <p className="flex gap-x-1.5 items-center mt-2.5 text-txt-40 text-xs font-semibold underline">
                                            Bakı şəhəri, Bakı ş. Nəsimi r., Azadlıq pr.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="pr-5 h-[420px] flex gap-x-5 ">
                                <img
                                    src={advert.images[0]}
                                    alt="Advert 1"
                                    className="mt-auto lg:mt-0 md:w-[190px] md:h-[316px] lg:w-[232px] lg:h-[420px] object-cover rounded-2xl"
                                    style={{
                                        boxShadow: '1px 4px 16px 0px rgba(0, 0, 0, 0.15)',
                                    }}
                                />
                                <div className="flex flex-col gap-y-5 lg:-translate-y-10">
                                    <img
                                        src={advert.images[1]}
                                        alt="Advert 2"
                                        className="md:w-[190px] md:h-[190px] lg:w-[232px] lg:h-[250px] object-cover rounded-2xl"
                                        style={{
                                            boxShadow: '1px 4px 16px 0px rgba(0, 0, 0, 0.15)',
                                        }}
                                    />
                                    <img
                                        src={advert.images[2]}
                                        alt="Advert 3"
                                        className="md:w-[190px] lg:w-[232px] h-[150px] object-cover rounded-2xl"
                                        style={{
                                            boxShadow: '1px 4px 16px 0px rgba(0, 0, 0, 0.15)',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
};

export default PremiumAdvertSlider;
