import advertisementTempImg from 'assets/images/temp-5.png';
import Modal from 'components/common/modal';
import Register from 'components/register';
import { useModal } from 'hooks/use-modal';

const RegisterModal = () => {
    const { isOpen, type, onClose, onOpen } = useModal();
    const isModalOpen = isOpen && type == 'register';

    return (
        <Modal
            isOpen={isModalOpen}
            onClose={onClose}
            modalContainerClassName="hidden md:flex"
            modalClassName="md:w-[925px] lg:w-[1080px] py-[50px] md:px-[50px] lg:px-[100px] bg-white flex justify-between items-center rounded-[16px]"
        >
            <img
                src={advertisementTempImg}
                alt="advertisement"
                className="md:w-[350px] lg:w-[400px] md:h-[400px] lg:h-[459px] object-cover"
            />
            <Register onLoginOpen={() => onOpen('login')} />
        </Modal>
    );
};

export default RegisterModal;
