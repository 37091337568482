export const paths = {
    home: '/',
    terms: '/terms-and-conditions',
    cabinet: {
        main: '/cabinet',
        profile: '/cabinet/profile',
        agency: '/cabinet/agency',
        advert: '/cabinet/advert',
        bookmark: '/cabinet/bookmark',
        plan: '/cabinet/plan',
        billing: '/cabinet/billing',
    },
    advert: {
        create: '/advert/create',
        view: (id = ':id') => `/advert/${id}`,
    },
    agencies: '/agencies',
    agency: (id = ':id') => `/agency/${id}`,
    residentialComplexes: {
        list: '/residential-complexes',
        view: (id = ':id') => `/residential-complexes/${id}`,
    },
};
