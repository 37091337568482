import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import TempImg from 'assets/images/temp.png';
import React, { useCallback, useRef } from 'react';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const SLIDES = [
    {
        title: 'Boulevard Residence',
        description:
            'Boulevard Residence - Bakı şəhəri Səbail rayonu, ön sırasında, Dənizkanarı bulvarın qarşısında, Dövlət Bayraq Meydanından 850 metr, dənizdən 200 metr aralı yerləşən yüksəksəviyyəli Yaşayış Kompleksdir.',
        imgSrc: TempImg,
    },
    {
        title: '28 Residence',
        description:
            '"28 Residence" layihəsi Bakı şəhərinin yeni baş planına əsasən inşa edilir. Layihəniin əsas üstünlüyü ekoloji cəhətdən təmiz ərazidə yerləşməsidir. Yaşayış kompleksi "28 May" metrostansiyasının yeni çıxışı...',
        imgSrc: TempImg,
    },
    {
        title: 'Port Residence',
        description:
            'Boulevard Residence 2 - Bakı şəhəri Səbail rayonu, ön sırasında, Dənizkanarı bulvarın qarşısında, Dövlət Bayraq Meydanından 850 metr, dənizdən 200 metr aralı yerləşən yüksəksəviyyəli Yaşayış Kompleksdir.',
        imgSrc: TempImg,
    },
];

const LandingSlider = () => {
    const sliderRef = useRef<any>(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);
    return (
        <Swiper
            ref={sliderRef}
            className="w-full duration-150 m-0"
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            effect="fade"
            fadeEffect={{
                crossFade: true,
            }}
            loop={true}
            modules={[EffectFade, Navigation, Pagination, Autoplay]}
        >
            {SLIDES.map((slide, idx) => (
                <SwiperSlide key={idx} className="px-5">
                    <h1 className="text-txt md:text-4xl lg:text-5xl font-bold tracking-[1.92px] whitespace-nowrap ">
                        {slide.title}
                    </h1>
                    <p className="text-dark-60 text-[15px] leading-5 mt-3 mb-4">
                        {slide.description}
                    </p>
                    <img
                        src={slide.imgSrc}
                        alt="Slide"
                        className="w-full md:h-[275px] lg:h-[300px] object-cover rounded-[15px]"
                    />
                </SwiperSlide>
            ))}
            <button
                className="absolute left-[2px] bottom-[calc(50%-80px)] md:bottom-[118px] lg:bottom-[130px] z-10 w-10 h-10 bg-white rounded-full grid place-items-center border border-transparent hover:border-primary duration-100"
                style={{
                    boxShadow:
                        'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                }}
                onClick={handlePrev}
            >
                <ArrowLeftIcon className="mt-0.5 mr-0.5" />
            </button>
            <button
                className="absolute right-[2px] bottom-[calc(50%-80px)] md:bottom-[118px] lg:bottom-[130px] z-10 w-10 h-10 bg-white rounded-full grid place-items-center border border-transparent hover:border-primary duration-100"
                style={{
                    boxShadow:
                        'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                }}
                onClick={handleNext}
            >
                <ArrowLeftIcon className="mt-0.5 ml-1 rotate-180" />
            </button>
        </Swiper>
    );
};

export default LandingSlider;
