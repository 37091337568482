import React from 'react';
import TempImage from 'assets/images/temp-16.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import { Autoplay } from 'swiper/modules';

const TopAdvertisings = () => {
    return (
        <div className="md:w-[925px] lg:w-[1070px] mx-auto z-10 relative md:-mt-12 mb-10">
            <Swiper
                className="w-full duration-150 m-0 p-2"
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    0: {
                        slidesPerView: 3,
                    },
                    400: {
                        slidesPerView: 4,
                    },
                    480: {
                        slidesPerView: 5,
                    },
                    640: {
                        slidesPerView: 6,
                    },
                    768: {
                        slidesPerView: 7,
                    },
                    925: {
                        slidesPerView: 7,
                    },
                    1070: {
                        slidesPerView: 9,
                    },
                }}
                effect="fade"
                fadeEffect={{
                    crossFade: true,
                }}
                loop={true}
                modules={[Autoplay]}
            >
                {[...Array(9)].map((_, idx) => (
                    <SwiperSlide key={idx} className="px-5">
                        <div className="rounded-full border border-dark-20 w-24 h-24 overflow-hidden cursor-pointer group hover:scale-110 transition">
                            <img
                                src={TempImage}
                                alt=""
                                className="w-full h-full group-hover:scale-110 transition"
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default TopAdvertisings;
