import { cn } from 'lib/utils';
import React, { forwardRef } from 'react';

type Props = {
    className?: string;
    error?: string;
    rightText?: React.ReactNode;
} & React.InputHTMLAttributes<HTMLInputElement>;

const InputUnderline = forwardRef<HTMLInputElement, Props>(
    ({ error, className, rightText, ...props }, ref) => {
        return (
            <div className="w-full">
                <div className="relative w-full">
                    <input
                        className={cn(
                            'max-w-full w-full py-2 px-2.5 pl-1.5 lg:pl-2.5  border-b border-txt-40 placeholder:text-txt-40 placeholder:font-medium outline-none hover:border-primary/70 focus:border-primary duration-100 bg-transparent disabled:cursor-not-allowed disabled:opacity-80',
                            rightText && 'pr-[26px] lg:pr-10',
                            className,
                        )}
                        ref={ref}
                        onFocus={(e) =>
                            e.target.addEventListener(
                                'wheel',
                                function (e) {
                                    e.preventDefault();
                                },
                                { passive: false },
                            )
                        }
                        {...props}
                    />
                    {rightText && (
                        <p className="text-xs lg:text-base absolute text-muted-foreground uppercase right-0.5 lg:right-1 top-3 lg:top-2">
                            {rightText}
                        </p>
                    )}
                </div>
                <p className="text-red-600 text-xs h-4 mt-1">{error}</p>
            </div>
        );
    },
);

export default InputUnderline;
