import { RenderIf } from 'components/common/render-if';
import { Spinner } from 'components/common/spinner';
import { cn } from 'lib/utils';
import React from 'react';

interface ButtonPrimaryProps {
    text: string;
    disabled?: boolean;
    loading?: boolean;
    icon?: React.ReactNode;
    className?: string;
}

const ButtonPrimary = ({ loading, text, disabled, icon, className }: ButtonPrimaryProps) => {
    return (
        <button
            className={cn(
                'h-12 cursor-pointer bg-primary w-full disabled:opacity-80 flex items-center justify-center gap-x-3 text-white font-bold tracking-[0.64px] rounded-[14px] hover:bg-primary/80 duration-100',
                className,
            )}
            disabled={disabled || loading}
        >
            <RenderIf condition={!!loading}>
                <Spinner size={14} color="#fff" />
            </RenderIf>
            {icon}
            {text}
        </button>
    );
};

export default ButtonPrimary;
