import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { cn } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { Trash2Icon, RotateCwIcon, RotateCcwIcon } from 'lucide-react';
import { toast } from 'sonner';
import { FileWithPreview } from 'types';
import { v4 as uuidv4 } from 'uuid';
import { RenderIf } from '../render-if';

interface UploadProps {
    limit?: number;
    minSize?: number;
    type?: 'photo' | 'video';
    onChange?: (files: FileWithPreview[]) => void;
    error?: string;
}

const Upload: React.FC<UploadProps> = ({
    limit = 10,
    minSize = 0,
    type = 'photo',
    error,
    onChange,
}) => {
    const [files, setFiles] = useState<FileWithPreview[]>([]);
    const { t } = useTranslation();

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (files.length + acceptedFiles.length > limit) {
                return toast.warning(t('upload.limit-exceeded'));
            }
            const newFiles = acceptedFiles.map((file, index) => ({
                id: `${uuidv4()}-${file.name}-${file.size}`,
                preview: URL.createObjectURL(file),
                index: files.length + index,
                rotate: 0,
                file,
            }));
            setFiles((prev) => {
                const updatedFiles = [...prev, ...newFiles];
                onChange?.(updatedFiles);
                return updatedFiles;
            });
        },
        [files, limit, onChange, t],
    );

    const reorder = (list: FileWithPreview[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;
        const reorderedFiles = reorder(files, result.source.index, result.destination.index);
        setFiles(reorderedFiles);
        onChange?.(reorderedFiles);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: limit,
        minSize,
        accept: {
            ...(type === 'photo' ? { 'image/*': [] } : { 'video/*': [] }),
        },
        multiple: true,
        onDropRejected: (rejectedFiles) => {
            if (files.length + rejectedFiles.length > limit) {
                toast.warning(t('upload.limit-exceeded'));
            }
        },
    });

    const handleDelete = (fileId: string) => {
        setFiles((prevFiles) => {
            const updatedFiles = prevFiles.filter((file) => file.id !== fileId);
            onChange?.(updatedFiles);
            return updatedFiles;
        });
    };

    const handleRotate = (fileId: string, value: number) => {
        setFiles((prevFiles) => {
            const updatedFiles = prevFiles.map((file) => {
                if (file.id === fileId) {
                    const newRotate = (file.rotate + value) % 4; // Rotate between 0 to 3 (0, 90, 180, 270 degrees)
                    return { ...file, rotate: newRotate };
                }
                return file;
            });
            onChange?.(updatedFiles);
            return updatedFiles;
        });
    };

    return (
        <div className="w-full my-8">
            {error && <p className="text-red-600 text-sm mb-2">{error}</p>}
            <div
                {...getRootProps()}
                className={cn(
                    'p-8 border-dashed border-2 rounded-md mb-4 cursor-pointer',
                    isDragActive ? 'border-brend' : 'border-brend-20',
                )}
            >
                <input {...getInputProps()} />
                <p className="text-center text-gray-500">{t(`upload.upload-${type}`)}</p>
                <p className="text-center text-gray-500 text-sm">
                    {t('upload.limit', { count: limit - files.length })}
                </p>
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="uploads" direction="vertical">
                    {(provided) => (
                        <div
                            className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 overflow-hidden"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {files.map((file, index) => (
                                <Draggable key={file.id} draggableId={file.id} index={index}>
                                    {(provided) => (
                                        <div
                                            className="px-2 pt-8 pb-2 border-dashed border-2 overflow-hidden border-dark-20 rounded-md"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <div className="relative flex flex-col items-center">
                                                {type === 'photo' ? (
                                                    <img
                                                        src={file.preview}
                                                        alt="preview"
                                                        style={{
                                                            transform: `rotate(${
                                                                file.rotate * 90
                                                            }deg)`,
                                                        }}
                                                        className="w-full h-24 md:h-44 object-contain mb-2"
                                                    />
                                                ) : (
                                                    <video
                                                        src={file.preview}
                                                        className="w-full h-24 md:h-44 object-contain mb-2"
                                                        controls
                                                    />
                                                )}
                                                <p className="text-sm text-muted-foreground mt-2 z-20">
                                                    {index === 0
                                                        ? t(`upload.main-${type}`)
                                                        : t(`upload.${type}`, { index: index + 1 })}
                                                </p>
                                                <RenderIf condition={type === 'photo'}>
                                                    <button
                                                        type="button"
                                                        className="absolute left-6 bottom-0 text-muted-foreground"
                                                        onClick={() => handleRotate(file.id, 1)}
                                                    >
                                                        <RotateCwIcon className="w-5 h-5" />
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="absolute left-0 bottom-0 text-muted-foreground"
                                                        onClick={() => handleRotate(file.id, -1)}
                                                    >
                                                        <RotateCcwIcon className="w-5 h-5" />
                                                    </button>
                                                </RenderIf>
                                                <button
                                                    type="button"
                                                    className="absolute right-0 bottom-0 text-red-600 text-sm rounded"
                                                    onClick={() => handleDelete(file.id)}
                                                >
                                                    <Trash2Icon className="w-5 h-5" />
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default Upload;
