import { ReactComponent as BookmarkIcon } from 'assets/icons/bookmark.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home-rounded.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as SubtractIcon } from 'assets/icons/subtract.svg';
import { paths } from 'lib/constants/paths';
import { cn } from 'lib/utils';
import { MobileTab, useMobileTab } from 'hooks/use-mobile-tab';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectUser } from 'store/features/userSlice';

const MobileNavigation = () => {
    const { tab, onTabChange } = useMobileTab();
    const { user } = useSelector(selectUser);
    const navigate = useNavigate();

    const onTabClick = (tab: MobileTab) => {
        onTabChange(tab);
        navigate('/');
    };

    return (
        <div
            className="md:hidden fixed bottom-0 left-0 w-full h-[76px] px-2 sm:px-5 pt-4 pb-3 z-30 bg-white flex gap-x-[4.5px] items-center"
            style={{
                boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
            }}
        >
            <button
                className={cn(
                    'flex flex-col w-full gap-y-2 justify-center items-center',
                    tab === 'home' ? 'fill-brend text-brend' : 'fill-txt-60 text-txt-60',
                )}
                onClick={() => onTabClick('home')}
            >
                <HomeIcon className="fill-inherit" />
                <span className="text-[10px] font-medium">DAHA ÇOX</span>
            </button>
            <div className="h-[44px] w-[1px] bg-dark-20" />
            <button
                className={cn(
                    'flex flex-col w-full gap-y-2 justify-center items-center',
                    tab === 'bookmars' ? 'fill-brend text-brend' : 'fill-txt-60 text-txt-60',
                )}
                onClick={() => onTabClick('bookmars')}
            >
                <BookmarkIcon className="fill-inherit" />
                <span className="text-[10px] font-medium">SEÇİLMİŞLƏR</span>
            </button>
            <div className="h-[44px] w-[1px] bg-dark-20" />
            <button
                className={cn(
                    'flex flex-col w-full gap-y-[5px] justify-center items-center',
                    tab === 'createAdvert' ? 'fill-brend text-brend' : 'fill-txt-60 text-txt-60',
                )}
                onClick={() => onTabClick('createAdvert')}
            >
                <SubtractIcon className="fill-inherit" />
                <span className="text-[10px] font-medium">YENI ELAN</span>
            </button>
            <div className="h-[44px] w-[1px] bg-dark-20" />
            <button
                className={cn(
                    'flex flex-col w-full gap-y-2 justify-center items-center',
                    tab === 'search' ? 'fill-brend text-brend' : 'fill-txt-60 text-txt-60',
                )}
                onClick={() => onTabClick('search')}
            >
                <SearchIcon className="fill-inherit w-[22px] h-[22px]" />
                <span className="text-[10px] font-medium">AXTARIŞ</span>
            </button>
            <div className="h-[44px] w-[1px] bg-dark-20" />
            <button
                className={cn(
                    'flex flex-col w-full gap-y-2 justify-center items-center',
                    ['login', 'register', 'profile'].includes(tab)
                        ? 'fill-brend text-brend'
                        : 'fill-txt-60 text-txt-60',
                )}
                onClick={() => {
                    if (user) {
                        navigate(paths.cabinet.main);
                    } else {
                        onTabClick('login');
                    }
                }}
            >
                <ProfileIcon className="fill-inherit w-[22px] h-[22px]" />
                <span className="text-[10px] font-medium">KABİNET</span>
            </button>
        </div>
    );
};

export default MobileNavigation;
