import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedin.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter.svg';
import { ReactComponent as LogoImg } from 'assets/images/logo.svg';
import CustomSelectOutline from 'components/common/custom-select/outline';
import { useState } from 'react';
import { FiPhone } from 'react-icons/fi';
import { IoMailOpenOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';

const Footer = () => {
    const [selectedCurrency, setSelectedCurrency] = useState({ label: 'AZN', value: 'azn' });
    const [selectedLanguage, setSelectedLanguage] = useState({ label: 'AZ', value: 'az' });

    return (
        <footer className="mt-20 hidden md:block w-full bg-brend-05 md:py-[35px] lg:py-[50px]">
            <div className="md:w-[912px] lg:w-[1286px] mx-auto py-10 md:px-[48px] lg:px-[92px] border-[3px] border-txt-60 flex justify-between items-center">
                <div className="w-[484px]">
                    <LogoImg className="fill-brend" />
                    <div className="mt-5 w-[416px]">
                        <label
                            className="flex mb-3 text-txt-60 font-medium text-[15px]"
                            htmlFor="footer-contact-with-number"
                        >
                            Biz Sizə zəng edək.
                        </label>
                        <div className="flex gap-x-4">
                            <input
                                id="footer-contact-with-number"
                                type="text"
                                className="px-4 py-3 border border-txt-60 rounded-[5px] text-[15px] w-full placeholder:text-txt-40 placeholder:font-medium hover:border-brend focus:border-primary focus:hover:border-primary outline-none duration-100"
                                placeholder="Nömrənizi qeyd edin"
                            />
                            <button className="px-4 py-3 text-txt-60 border border-txt-60 rounded-[5px] text-[15px] hover:bg-primary hover:text-white duration-100">
                                Təsdiqlə
                            </button>
                        </div>
                    </div>
                    <div className="mt-5 w-[416px]">
                        <label
                            className="flex mb-3 text-txt-60 font-medium text-[15px]"
                            htmlFor="footer-contact-with-email"
                        >
                            Ən son elanları E-mail üçvanına almaq üçün.
                        </label>
                        <div className="flex gap-x-4">
                            <input
                                id="footer-contact-with-email"
                                type="text"
                                className="px-4 py-3 border border-txt-60 rounded-[5px] text-[15px] w-full placeholder:text-txt-40 placeholder:font-medium hover:border-brend focus:border-primary focus:hover:border-primary outline-none duration-100"
                                placeholder="E-mail daxil edin"
                            />
                            <button className="px-4 py-3 text-txt-60 border border-txt-60 rounded-[5px] text-[15px] hover:bg-primary hover:text-white duration-100">
                                Təsdiqlə
                            </button>
                        </div>
                    </div>
                    <p className="mt-5 text-xs font-medium text-txt-60">
                        Abunə olmaqla siz Məxfilik Siyasətimizlə razılaşırsınız və şirkətimizdən
                        yenilikləri almağa razılıq verirsiniz.
                    </p>
                </div>
                <div className="flex gap-x-4 lg:gap-x-10">
                    <div>
                        <h4 className="mb-[30px] text-xl font-bold text-txt-60">Kompaniya</h4>
                        <ul className="flex flex-col gap-y-2 text-base leading-[19px]">
                            <Link to="/">
                                <li className="cursor-pointer duration-100 hover:text-txt-40 flex gap-x-2.5 items-center text-txt-60 font-medium">
                                    <ArrowIcon />
                                    Elanlar
                                </li>
                            </Link>
                            <Link to="/">
                                <li className="cursor-pointer duration-100 hover:text-txt-40 flex gap-x-2.5 items-center text-txt-60 font-medium">
                                    <ArrowIcon />
                                    Haqqımızda
                                </li>
                            </Link>
                            <Link to="/">
                                <li className="cursor-pointer duration-100 hover:text-txt-40 flex gap-x-2.5 items-center text-txt-60 font-medium">
                                    <ArrowIcon />
                                    Aqentlərimiz
                                </li>
                            </Link>
                            <Link to="/">
                                <li className="cursor-pointer duration-100 hover:text-txt-40 flex gap-x-2.5 items-center text-txt-60 font-medium">
                                    <ArrowIcon />
                                    Blog
                                </li>
                            </Link>
                            <Link to="/">
                                <li className="cursor-pointer duration-100 hover:text-txt-40 flex gap-x-2.5 items-center text-txt-60 font-medium">
                                    <ArrowIcon />
                                    Xidmətlər
                                </li>
                            </Link>
                            <Link to="/">
                                <li className="cursor-pointer duration-100 hover:text-txt-40 flex gap-x-2.5 items-center text-txt-60 font-medium">
                                    <ArrowIcon />
                                    Yaşayış kompleksləri
                                </li>
                            </Link>
                        </ul>
                    </div>
                    <div>
                        <h4 className="mb-[30px] text-xl font-bold text-txt-60">Bizi takib edin</h4>
                        <ul className="flex flex-col gap-y-5 text-[15px]">
                            <Link to="/">
                                <li className="cursor-pointer duration-100 hover:text-txt-40 flex gap-x-2.5 items-center text-txt-60 font-medium">
                                    <FacebookIcon />
                                    Facebook
                                </li>
                            </Link>
                            <Link to="/">
                                <li className="cursor-pointer duration-100 hover:text-txt-40 flex gap-x-2.5 items-center text-txt-60 font-medium">
                                    <InstagramIcon />
                                    Instagram
                                </li>
                            </Link>
                            <Link to="/">
                                <li className="cursor-pointer duration-100 hover:text-txt-40 flex gap-x-2.5 items-center text-txt-60 font-medium">
                                    <TwitterIcon />
                                    Twitter
                                </li>
                            </Link>
                            <Link to="/">
                                <li className="cursor-pointer duration-100 hover:text-txt-40 flex gap-x-2.5 items-center text-txt-60 font-medium">
                                    <LinkedinIcon />
                                    Linkedin
                                </li>
                            </Link>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="md:w-[912px] lg:w-[1286px] mx-auto mt-6 relative z-10 hidden md:flex text-txt-60 h-9 items-center justify-between  text-xs">
                <div className="flex">
                    <p className="flex items-center gap-x-2 w-[180px]">
                        <FiPhone />
                        <a href="tel:994(55) 555-55-55">994(55) 555-55-55</a>
                    </p>
                    <p className="flex items-center gap-x-2 w-[150px]">
                        <FiPhone />
                        <a href="tel:994(55) 555-55-55">994(55) 555-55-55</a>
                    </p>
                </div>
                <div className="flex items-center md:gap-x-8 lg:gap-x-[70px]">
                    <p className="flex items-center justify-end gap-x-2 w-[100px]">
                        <IoMailOpenOutline className="w-4 h-4" />
                        <a href="mailto:info@darx.az">info@darx.az</a>
                    </p>
                    <CustomSelectOutline
                        selectedOption={selectedCurrency}
                        onChange={(option) => setSelectedCurrency(option)}
                        options={[
                            { label: 'AZN', value: 'azn' },
                            { label: 'EUR', value: 'euro' },
                            { label: 'USD', value: 'usd' },
                        ]}
                        inputClassName="border-txt-60"
                        iconClassName="fill-txt-60"
                    />
                    <CustomSelectOutline
                        selectedOption={selectedLanguage}
                        onChange={(option) => setSelectedLanguage(option)}
                        options={[
                            { label: 'AZ', value: 'az' },
                            { label: 'EN', value: 'en' },
                            { label: 'RU', value: 'ru' },
                        ]}
                        inputClassName="border-txt-60"
                        iconClassName="fill-txt-60"
                    />
                </div>
            </div>
        </footer>
    );
};

export default Footer;
