import {
    ADVERT_OWNER_TYPE,
    ADVERT_TYPE,
    BUILDING_TYPE,
    DOCUMENT_STATUS,
    MAINTENANCE_STATUS,
    MORTGAGE_STATUS,
    PRICE_TYPE,
    PROPERTY_TYPE,
} from 'lib/constants';

export const ownerOptions = [
    {
        value: ADVERT_OWNER_TYPE.OWNER,
        label: 'advert.owner',
    },
    {
        value: ADVERT_OWNER_TYPE.AGENCY,
        label: 'advert.agency',
    },
];
export const advertTypeOptions = [
    {
        value: ADVERT_TYPE.RENT,
        label: 'advert.rent',
    },
    {
        value: ADVERT_TYPE.SALE,
        label: 'advert.sale',
    },
];
export const propertyTypeOptions = [
    {
        value: PROPERTY_TYPE.NEW_BUILDING,
        label: 'advert.new-building',
    },
    {
        value: PROPERTY_TYPE.OLD_BUILDING,
        label: 'advert.old-building',
    },
    {
        value: PROPERTY_TYPE.YARD_HOUSE,
        label: 'advert.yard-house',
    },
    {
        value: PROPERTY_TYPE.OFFICE,
        label: 'advert.office',
    },
    {
        value: PROPERTY_TYPE.GARAGE,
        label: 'advert.garage',
    },
    {
        value: PROPERTY_TYPE.LAND,
        label: 'advert.land',
    },
    {
        value: PROPERTY_TYPE.COMMERCIAL,
        label: 'advert.commercial',
    },
];

export const maintenanceOptions = [
    { value: MAINTENANCE_STATUS.FULL, label: 'advert.full-repair' },
    { value: MAINTENANCE_STATUS.AVERAGE, label: 'advert.average-repair' },
    { value: MAINTENANCE_STATUS.BAD, label: 'advert.without-repair' },
];
export const documentStatusOptions = [
    { value: DOCUMENT_STATUS.YES, label: 'advert.with-document' },
    { value: DOCUMENT_STATUS.NO, label: 'advert.without-document' },
];
export const mortgageStatusOptions = [
    { value: MORTGAGE_STATUS.YES, label: 'advert.with-mortgage' },
    { value: MORTGAGE_STATUS.NO, label: 'advert.without-mortgage' },
];
export const buildingTypeOptions = [
    { value: BUILDING_TYPE.BUSINNESS_CENTER, label: 'advert.business-center' },
    { value: BUILDING_TYPE.HOME, label: 'advert.home' },
    { value: BUILDING_TYPE.VILLA, label: 'advert.villa' },
];
export const priceTypeOptions = [
    { value: PRICE_TYPE.MONTHLY, label: 'advert.monthly' },
    { value: PRICE_TYPE.DAILY, label: 'advert.daily' },
];
