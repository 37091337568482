import FacebookButton from 'components/common/button/facebook';
import GoogleButton from 'components/common/button/google';
import ButtonOutline from 'components/common/button/outline';
import InputUnderline from 'components/common/input/underline';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { usePostApiV1ClientIdentityLoginMutation } from 'service/api/allApis';
import { toast } from 'sonner';

interface LoginProps {
    onRegisterOpen: () => void;
    onLoginClose: () => void;
}

type FormValues = {
    email: string;
    password: string;
};

const getSchema = (t: TFunction<'translation', undefined>) =>
    yup
        .object({
            email: yup
                .string()
                .required(t('validation.field-required', { field: t('auth.email') })),
            password: yup
                .string()
                .required(t('validation.field-required', { field: t('auth.password') })),
        })
        .required();

const Login = ({ onRegisterOpen, onLoginClose }: LoginProps) => {
    const [loginMutation, { isLoading }] = usePostApiV1ClientIdentityLoginMutation();
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: yupResolver(getSchema(t)),
    });

    const onSubmit: SubmitHandler<FormValues> = (values) => {
        loginMutation({
            darxApplicationDtOsIdentityDtOsLoginDto: {
                email: values.email,
                password: values.password,
            },
        })
            .unwrap()
            .then(() => {
                toast.success(t('auth.login-success'));
                onLoginClose();
            })
            .catch((e) => {
                const errorMessage = e?.data?.Title ?? t('general.something-went-wrong');
                toast.error(errorMessage);
            });
    };

    return (
        <div className="w-[352px] flex flex-col items-center border border-dark-20 py-[30px] px-10">
            <h2 className="text-[32px] font-bold text-txt">{t('auth.welcome')}</h2>
            <div className="mt-5 mb-8 flex items-center gap-x-3">
                <p className="text-sm text-txt-60 font-medium">{t('auth.dont-have-an-account')}</p>
                <button
                    onClick={onRegisterOpen}
                    className=" text-brend text-sm font-semibold underline hover:text-primary cursor-pointer"
                >
                    {t('auth.register')}
                </button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col gap-y-8">
                <InputUnderline
                    type="text"
                    autoComplete="email"
                    placeholder={t('auth.email')}
                    {...register('email')}
                    error={errors.email?.message}
                />
                <InputUnderline
                    type="password"
                    autoComplete="current-password"
                    placeholder={t('auth.password')}
                    {...register('password')}
                    error={errors.password?.message}
                />
                <ButtonOutline
                    loading={isLoading}
                    text={t('auth.login')}
                    className="rounded-sm text-txt-60 border border-txt-60"
                />
                <p className="text-center text-sm font-medium text-txt-60">{t('general.or')}</p>
                <div className="flex flex-col gap-y-[14px] relative">
                    <FacebookButton />
                    <GoogleButton />
                    <div className="absolute top-0 left-0 right-0 bottom-0 bg-black/60 rounded-2xl flex items-center justify-center text-lg text-white uppercase font-bold">
                        <p className="-rotate-[15deg] tracking-widest">
                            {t('general.coming-soon')}
                        </p>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Login;
