import { ReactComponent as GoogleIcon } from 'assets/icons/google.svg';
import React from 'react';

const GoogleButton = () => {
    return (
        <button className="bg-[#CD2121] w-full rounded-2xl h-[42px] flex justify-center gap-x-3 text-white items-center hover:bg-transparent border-2 border-transparent hover:border-[#CD2121] hover:text-[#CD2121] group duration-100 font-medium cursor-pointer">
            <GoogleIcon className="group-hover:fill-[#CD2121]" />
            Google
        </button>
    );
};

export default GoogleButton;
