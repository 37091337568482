import { cn } from 'lib/utils';
import { Spinner } from '../spinner';

export const LoadingOverlay = ({ fullScreen = false }) => {
    return (
        <div
            className={cn(
                'w-full bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-[rgb(194,169,112)] to-[rgb(107,89,46)] flex items-center justify-center flex-col gap-y-4',
                fullScreen ? 'absolute top-[132px] h-[calc(100vh-95px)] z-20' : 'h-full',
            )}
        >
            <Spinner size={100} />
        </div>
    );
};
