import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import z from 'zod';

import AboutAdvertStep from './about-advert';
import AboutAddressStep from './about-address';
import AboutPropertyStep from './about-property';
import ButtonPrimary from 'components/common/button/primary';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { CreateAdvertFormValues } from 'pages/advert-create/types';
import AboutAnouncerStep from './about-anouncer';
import { Link, useNavigate } from 'react-router-dom';
import { paths } from 'lib/constants/paths';
import { usePostApiV1ClientAdsMutation } from 'service/api/allApis';
import { toast } from 'sonner';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/features/userSlice';

export const getCreateAdvertSchema = (t: TFunction<'translation', undefined>) =>
    z.object({
        propertyArea: z.preprocess(
            (a) => parseFloat(z.any().parse(a)),
            z.number({ message: t('validation.required') }),
        ),
        propertyLandArea: z.preprocess(
            (a) => parseFloat(z.any().parse(a)),
            z.number({ message: t('validation.required') }),
        ),
        roomCount: z.preprocess(
            (a) => parseInt(z.any().parse(a), 10),
            z.number({ message: t('validation.required') }).int(),
        ),
        buildingFloorCount: z.preprocess(
            (a) => parseInt(z.any().parse(a), 10),
            z.number({ message: t('validation.required') }).int(),
        ),
        propertyFloor: z.preprocess(
            (a) => parseInt(z.any().parse(a), 10),
            z.number({ message: t('validation.required') }).int(),
        ),
        price: z.preprocess(
            (a) => parseFloat(z.any().parse(a)),
            z.number({ message: t('validation.required') }).positive({
                message: t('validation.positive'),
            }),
        ),
        advertOwnerType: z.number().int(),
        advertType: z.number().int(),
        propertyType: z.number().int(),
        maintenanceStatus: z.number().int(),
        buildingType: z.number().int(),
        documentStatus: z.number().int(),
        mortgageStatus: z.number().int(),
        priceType: z.number().int(),
        description: z.string().min(10, t('validation.min', { count: 10 })),
        countryId: z
            .string()
            .min(1, t('validation.field-required', { field: t('general.country') })),
        cityId: z.string().min(1, t('validation.field-required', { field: t('general.city') })),
        districtId: z.string(),
        metroId: z.string(),
        name: z.string(),
        surname: z.string(),
        email: z.string().email(t('validation.email')),
        phoneNumber: z
            .string()
            .min(10, t('validation.field-required', { field: t('auth.phone-number') })),
        latitude: z.number(),
        longitude: z.number(),
        images: z
            .array(
                z.object({
                    file: z.instanceof(File).refine((file) => file.size < 2 * 1024 * 1024, {
                        message: t('validation.image-size', { size: 2 }),
                    }),
                    id: z.string(),
                    index: z.number(),
                    preview: z.string(),
                    rotate: z.number(),
                }),
            )
            .min(5, t('validation.image-min', { count: 5 })),
        videos: z.array(
            z.object({
                file: z.instanceof(File),
                id: z.string(),
                index: z.number(),
                preview: z.string(),
            }),
        ),
    });

const defaultValues: CreateAdvertFormValues = {
    advertOwnerType: 0,
    advertType: 0,
    propertyType: 0,
    maintenanceStatus: 0,
    documentStatus: 0,
    mortgageStatus: 0,
    propertyArea: 0,
    propertyLandArea: 0,
    buildingType: 0,
    roomCount: 0,
    price: 0,
    priceType: 0,
    buildingFloorCount: 0,
    propertyFloor: 0,
    images: [],
    videos: [],
    countryId: '',
    cityId: '',
    districtId: '',
    metroId: '',
    name: '',
    surname: '',
    email: '',
    phoneNumber: '',
    description: '',
    latitude: 40.409264,
    longitude: 49.867092,
};

const CreateAdvertSteps = () => {
    const { t } = useTranslation();
    const { user } = useSelector(selectUser);
    const navigate = useNavigate();
    const [createMutation, { isLoading: isCreateLoading }] = usePostApiV1ClientAdsMutation();
    const form = useForm<CreateAdvertFormValues>({
        resolver: zodResolver(getCreateAdvertSchema(t)),
        defaultValues,
    });

    const onSubmit = (values: CreateAdvertFormValues) => {
        const formData = new FormData();
        const keys: Array<keyof CreateAdvertFormValues> = [
            'advertOwnerType',
            'advertType',
            'propertyType',
            'maintenanceStatus',
            'documentStatus',
            'mortgageStatus',
            'priceType',
            'propertyArea',
            'price',
            'roomCount',
            'buildingType',
            'buildingFloorCount',
            'propertyFloor',
            'description',
            'countryId',
            'cityId',
            'districtId',
            'metroId',
            'name',
            'surname',
            'email',
            'phoneNumber',
            'propertyLandArea',
            'latitude',
            'longitude',
        ];
        keys.forEach((key) => {
            formData.append(key, values[key].toString());
        });
        values.images.forEach((image, index) => {
            formData.append(`Images[${index}].image`, image.file);
            formData.append(`Images[${index}].index`, image.index.toString());
            formData.append(`Images[${index}].rotate`, image.rotate.toString());
        });
        values.videos.forEach((video, index) => {
            formData.append(`Videos[${index}].video`, video.file);
            formData.append(`Videos[${index}].index`, video.index.toString());
        });

        createMutation({
            body: formData as any,
        })
            .unwrap()
            .then(() => {
                toast.success(t('advert.create-success'));
                navigate(paths.cabinet.advert);
            })
            .catch((e) => {
                const errorMessage =
                    e?.data?.Detail ?? e?.data?.Title ?? t('general.something-went-wrong');
                toast.error(errorMessage);
            });
    };

    return (
        <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="border-t-2 border-txt-40 w-full pb-6"
        >
            <AboutAdvertStep form={form} />
            <AboutPropertyStep form={form} />
            <AboutAddressStep form={form} />
            <AboutAnouncerStep form={form} />
            <p className="text-muted-foreground px-4 md:px-6 lg:px-8">
                {t('general.terms-1')}
                <Link to={paths.terms} className="text-brend underline">
                    {t('general.terms-2')}
                </Link>
                {t('general.terms-3')}
            </p>
            <div className="flex justify-end mt-10 px-4 md:px-6 lg:px-8">
                <ButtonPrimary
                    loading={isCreateLoading}
                    text={t('general.continue')}
                    className="w-56 rounded-md uppercase"
                />
            </div>
        </form>
    );
};

export default CreateAdvertSteps;
