import React from 'react';

interface AdvertWrapperProps {
    children: React.ReactNode;
}

const AdvertWrapper = ({ children }: AdvertWrapperProps) => {
    return (
        <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-5 md:gap-x-5">
            {children}
        </div>
    );
};

export default AdvertWrapper;
