import React, { useEffect, useState } from 'react';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';

type Props = {
    city: string | null;
    onMarkerPositionChange: (position: { lat: number; lng: number }) => void;
};

const defaultMapCenter = { lat: 40.409264, lng: 49.867092 };
export const GoogleMap = ({ city, onMarkerPositionChange }: Props) => {
    const [center, setCenter] = useState(defaultMapCenter);
    const [markerPosition, setMarkerPosition] = useState(defaultMapCenter);

    const getCityLocation = async () => {
        if (!city) return;

        const API_KEY = process.env.REACT_APP_MAP_API_KEY!;
        const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                city,
            )}&key=${API_KEY}`,
        );
        const data = await response.json();

        if (data.results && data.results[0]) {
            const location = data.results[0].geometry.location;
            setCenter({ lat: location.lat, lng: location.lng });
        }
    };

    const handleMarkerPositionChange = (position: { lat: number; lng: number }) => {
        setMarkerPosition(position);
        onMarkerPositionChange(position);
    };

    useEffect(() => {
        getCityLocation();
    }, [city]);

    return (
        <APIProvider apiKey={process.env.REACT_APP_MAP_API_KEY!}>
            <Map
                style={{ width: '100%', height: '400px' }}
                center={center}
                onCenterChanged={(e) => setCenter(e.detail.center)}
                defaultZoom={12}
                gestureHandling={'greedy'}
                onClick={(e) => e.detail.latLng && handleMarkerPositionChange(e.detail.latLng)}
            >
                <Marker position={markerPosition} />
            </Map>
        </APIProvider>
    );
};
