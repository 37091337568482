import { baseApi as api } from './baseApi';

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getApiV1ClientAds: build.query<GetApiV1ClientAdsApiResponse, GetApiV1ClientAdsApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/client/Ads`,
                params: {
                    pageNumber: queryArg.pageNumber,
                    pageSize: queryArg.pageSize,
                    isPaginated: queryArg.isPaginated,
                    status: queryArg.status,
                },
            }),
        }),
        postApiV1ClientAds: build.mutation<PostApiV1ClientAdsApiResponse, PostApiV1ClientAdsApiArg>(
            {
                query: (queryArg) => ({
                    url: `/api/v1/client/Ads`,
                    method: 'POST',
                    body: queryArg.body,
                }),
            },
        ),
        getApiV1ClientAdsGetAllCurrentUserAds: build.query<
            GetApiV1ClientAdsGetAllCurrentUserAdsApiResponse,
            GetApiV1ClientAdsGetAllCurrentUserAdsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/client/Ads/GetAllCurrentUserAds`,
                params: {
                    pageNumber: queryArg.pageNumber,
                    pageSize: queryArg.pageSize,
                    isPaginated: queryArg.isPaginated,
                    status: queryArg.status,
                },
            }),
        }),
        getApiV1ClientAdsById: build.query<
            GetApiV1ClientAdsByIdApiResponse,
            GetApiV1ClientAdsByIdApiArg
        >({
            query: (queryArg) => ({ url: `/api/v1/client/Ads/${queryArg.id}` }),
        }),
        putApiV1ClientAdsById: build.mutation<
            PutApiV1ClientAdsByIdApiResponse,
            PutApiV1ClientAdsByIdApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/client/Ads/${queryArg.id}`,
                method: 'PUT',
                body: queryArg.body,
            }),
        }),
        deleteApiV1ClientAdsById: build.mutation<
            DeleteApiV1ClientAdsByIdApiResponse,
            DeleteApiV1ClientAdsByIdApiArg
        >({
            query: (queryArg) => ({ url: `/api/v1/client/Ads/${queryArg.id}`, method: 'DELETE' }),
        }),
        getApiV1ClientAdvertisements: build.query<
            GetApiV1ClientAdvertisementsApiResponse,
            GetApiV1ClientAdvertisementsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/client/Advertisements`,
                params: {
                    pageNumber: queryArg.pageNumber,
                    pageSize: queryArg.pageSize,
                    isPaginated: queryArg.isPaginated,
                },
            }),
        }),
        getApiV1ClientAgencies: build.query<
            GetApiV1ClientAgenciesApiResponse,
            GetApiV1ClientAgenciesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/client/Agencies`,
                params: {
                    pageNumber: queryArg.pageNumber,
                    pageSize: queryArg.pageSize,
                    isPaginated: queryArg.isPaginated,
                },
            }),
        }),
        getApiV1ClientAgenciesById: build.query<
            GetApiV1ClientAgenciesByIdApiResponse,
            GetApiV1ClientAgenciesByIdApiArg
        >({
            query: (queryArg) => ({ url: `/api/v1/client/Agencies/${queryArg.id}` }),
        }),
        postApiV1ClientAuthResetPassword: build.mutation<
            PostApiV1ClientAuthResetPasswordApiResponse,
            PostApiV1ClientAuthResetPasswordApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/client/Auth/ResetPassword`,
                method: 'POST',
                body: queryArg.darxApplicationDtOsAuthDtOsResetPasswordDto,
            }),
        }),
        getApiV1ClientAuthVerifyPasswordResetToken: build.query<
            GetApiV1ClientAuthVerifyPasswordResetTokenApiResponse,
            GetApiV1ClientAuthVerifyPasswordResetTokenApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/client/Auth/VerifyPasswordResetToken`,
                params: {
                    userId: queryArg.userId,
                    resetToken: queryArg.resetToken,
                },
            }),
        }),
        postApiV1ClientAuthUpdatePasswordAsync: build.mutation<
            PostApiV1ClientAuthUpdatePasswordAsyncApiResponse,
            PostApiV1ClientAuthUpdatePasswordAsyncApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/client/Auth/UpdatePasswordAsync`,
                method: 'POST',
                body: queryArg.darxApplicationDtOsAuthDtOsUpdatePasswordDto,
            }),
        }),
        getApiV1ClientAuthRefreshTokenLoginAsync: build.query<
            GetApiV1ClientAuthRefreshTokenLoginAsyncApiResponse,
            GetApiV1ClientAuthRefreshTokenLoginAsyncApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/client/Auth/RefreshTokenLoginAsync`,
                params: {
                    refreshToken: queryArg.refreshToken,
                },
            }),
        }),
        getApiV1ClientBlogs: build.query<GetApiV1ClientBlogsApiResponse, GetApiV1ClientBlogsApiArg>(
            {
                query: (queryArg) => ({
                    url: `/api/v1/client/Blogs`,
                    params: {
                        pageNumber: queryArg.pageNumber,
                        pageSize: queryArg.pageSize,
                        isPaginated: queryArg.isPaginated,
                    },
                }),
            },
        ),
        getApiV1ClientBuildingTypes: build.query<
            GetApiV1ClientBuildingTypesApiResponse,
            GetApiV1ClientBuildingTypesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/client/BuildingTypes`,
                params: {
                    pageNumber: queryArg.pageNumber,
                    pageSize: queryArg.pageSize,
                    isPaginated: queryArg.isPaginated,
                },
            }),
        }),
        getApiV1ClientCities: build.query<
            GetApiV1ClientCitiesApiResponse,
            GetApiV1ClientCitiesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/client/Cities`,
                params: {
                    pageNumber: queryArg.pageNumber,
                    pageSize: queryArg.pageSize,
                    isPaginated: queryArg.isPaginated,
                    countryId: queryArg.countryId,
                },
            }),
        }),
        getApiV1ClientCountries: build.query<
            GetApiV1ClientCountriesApiResponse,
            GetApiV1ClientCountriesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/client/Countries`,
                params: {
                    pageNumber: queryArg.pageNumber,
                    pageSize: queryArg.pageSize,
                    isPaginated: queryArg.isPaginated,
                },
            }),
        }),
        getApiV1ClientDistricts: build.query<
            GetApiV1ClientDistrictsApiResponse,
            GetApiV1ClientDistrictsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/client/Districts`,
                params: {
                    pageNumber: queryArg.pageNumber,
                    pageSize: queryArg.pageSize,
                    isPaginated: queryArg.isPaginated,
                    cityId: queryArg.cityId,
                },
            }),
        }),
        postApiV1ClientIdentityRegister: build.mutation<
            PostApiV1ClientIdentityRegisterApiResponse,
            PostApiV1ClientIdentityRegisterApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/client/Identity/Register`,
                method: 'POST',
                body: queryArg.darxApplicationDtOsIdentityDtOsRegisterDto,
            }),
        }),
        putApiV1ClientIdentityUpdate: build.mutation<
            PutApiV1ClientIdentityUpdateApiResponse,
            PutApiV1ClientIdentityUpdateApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/client/Identity/Update`,
                method: 'PUT',
                body: queryArg.body,
            }),
        }),
        getApiV1ClientIdentityGetCurrentUser: build.query<
            GetApiV1ClientIdentityGetCurrentUserApiResponse,
            GetApiV1ClientIdentityGetCurrentUserApiArg
        >({
            query: () => ({ url: `/api/v1/client/Identity/GetCurrentUser` }),
        }),
        postApiV1ClientIdentityLogin: build.mutation<
            PostApiV1ClientIdentityLoginApiResponse,
            PostApiV1ClientIdentityLoginApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/client/Identity/Login`,
                method: 'POST',
                body: queryArg.darxApplicationDtOsIdentityDtOsLoginDto,
            }),
        }),
        getApiV1ClientLanguages: build.query<
            GetApiV1ClientLanguagesApiResponse,
            GetApiV1ClientLanguagesApiArg
        >({
            query: () => ({ url: `/api/v1/client/Languages` }),
        }),
        getApiV1ClientMetros: build.query<
            GetApiV1ClientMetrosApiResponse,
            GetApiV1ClientMetrosApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/client/Metros`,
                params: {
                    pageNumber: queryArg.pageNumber,
                    pageSize: queryArg.pageSize,
                    isPaginated: queryArg.isPaginated,
                    cityId: queryArg.cityId,
                },
            }),
        }),
        getApiV1ClientResidentialComplexes: build.query<
            GetApiV1ClientResidentialComplexesApiResponse,
            GetApiV1ClientResidentialComplexesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/client/ResidentialComplexes`,
                params: {
                    pageNumber: queryArg.pageNumber,
                    pageSize: queryArg.pageSize,
                    isPaginated: queryArg.isPaginated,
                },
            }),
        }),
        getApiV1ClientResidentialComplexesById: build.query<
            GetApiV1ClientResidentialComplexesByIdApiResponse,
            GetApiV1ClientResidentialComplexesByIdApiArg
        >({
            query: (queryArg) => ({ url: `/api/v1/client/ResidentialComplexes/${queryArg.id}` }),
        }),
        getApiV1ClientSliders: build.query<
            GetApiV1ClientSlidersApiResponse,
            GetApiV1ClientSlidersApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/client/Sliders`,
                params: {
                    pageNumber: queryArg.pageNumber,
                    pageSize: queryArg.pageSize,
                    isPaginated: queryArg.isPaginated,
                },
            }),
        }),
        getApiV1ClientSpecifications: build.query<
            GetApiV1ClientSpecificationsApiResponse,
            GetApiV1ClientSpecificationsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/client/Specifications`,
                params: {
                    pageNumber: queryArg.pageNumber,
                    pageSize: queryArg.pageSize,
                    isPaginated: queryArg.isPaginated,
                },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as allApis };
export type GetApiV1ClientAdsApiResponse =
    /** status 200 Success */ DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsAdsDtOsAdsGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull;
export type GetApiV1ClientAdsApiArg = {
    pageNumber?: number;
    pageSize?: number;
    isPaginated?: boolean;
    status?: DarxDomainEnumsAdsStatus;
};
export type PostApiV1ClientAdsApiResponse = unknown;
export type PostApiV1ClientAdsApiArg = {
    body: {
        AdvertOwnerType?: DarxDomainEnumsAdvertOwnerType;
        AdvertType?: DarxDomainEnumsAdvertType;
        PropertyType?: DarxDomainEnumsPropertyType;
        MaintenanceStatus?: DarxDomainEnumsMaintenanceStatus;
        DocumentStatus?: DarxDomainEnumsDocumentStatus;
        MortgageStatus?: DarxDomainEnumsMortgageStatus;
        PriceType?: DarxDomainEnumsPriceType;
        PropertyArea?: number;
        Price?: number;
        RoomCount?: number;
        BuildingType?: DarxDomainEnumsBuildingType;
        BuildingFloorCount?: number;
        PropertyFloor?: number;
        Description?: string;
        Images?: DarxApplicationDtOsAdsDtOsAdsImagePostDto[];
        AdsVideos?: DarxApplicationDtOsAdsDtOsAdsVideoPostDto[];
        CountryId?: string;
        CityId?: string;
        DistrictId?: string;
        MetroId?: string;
        Name?: string;
        Surname?: string;
        Email?: string;
        PhoneNumber?: string;
        PropertyLandArea?: number;
        Latitude?: string;
        Longitude?: string;
    };
};
export type GetApiV1ClientAdsGetAllCurrentUserAdsApiResponse =
    /** status 200 Success */ DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsAdsDtOsAdsGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull;
export type GetApiV1ClientAdsGetAllCurrentUserAdsApiArg = {
    pageNumber?: number;
    pageSize?: number;
    isPaginated?: boolean;
    status?: DarxDomainEnumsAdsStatus;
};
export type GetApiV1ClientAdsByIdApiResponse =
    /** status 200 Success */ DarxApplicationDtOsAdsDtOsAdsGetDto;
export type GetApiV1ClientAdsByIdApiArg = {
    id: string;
};
export type PutApiV1ClientAdsByIdApiResponse = unknown;
export type PutApiV1ClientAdsByIdApiArg = {
    id: string;
    body: {
        AdvertOwnerType?: DarxDomainEnumsAdvertOwnerType;
        AdvertType?: DarxDomainEnumsAdvertType;
        PropertyType?: DarxDomainEnumsPropertyType;
        MaintenanceStatus?: DarxDomainEnumsMaintenanceStatus;
        DocumentStatus?: DarxDomainEnumsDocumentStatus;
        MortgageStatus?: DarxDomainEnumsMortgageStatus;
        PriceType?: DarxDomainEnumsPriceType;
        PropertyArea?: number;
        Price?: number;
        RoomCount?: number;
        BuildingType?: DarxDomainEnumsBuildingType;
        BuildingFloorCount?: number;
        PropertyFloor?: number;
        Description?: string;
        Images?: DarxApplicationDtOsAdsDtOsAdsImageUpdateDto[];
        AdsVideos?: DarxApplicationDtOsAdsDtOsAdsVideoUpdateDto[];
        CountryId?: string;
        CityId?: string;
        DistrictId?: string;
        MetroId?: string;
        Name?: string;
        Surname?: string;
        Email?: string;
        PhoneNumber?: string;
        PropertyLandArea?: number;
        Latitude?: string;
        Longitude?: string;
    };
};
export type DeleteApiV1ClientAdsByIdApiResponse = unknown;
export type DeleteApiV1ClientAdsByIdApiArg = {
    id: string;
};
export type GetApiV1ClientAdvertisementsApiResponse =
    /** status 200 Success */ DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsAdvertisementDtOsAdvertisementGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull;
export type GetApiV1ClientAdvertisementsApiArg = {
    pageNumber?: number;
    pageSize?: number;
    isPaginated?: boolean;
};
export type GetApiV1ClientAgenciesApiResponse =
    /** status 200 Success */ DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsIdentityDtOsAdminAgencyUserGetDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull;
export type GetApiV1ClientAgenciesApiArg = {
    pageNumber?: number;
    pageSize?: number;
    isPaginated?: boolean;
};
export type GetApiV1ClientAgenciesByIdApiResponse =
    /** status 200 Success */ DarxApplicationDtOsIdentityDtOsAdminAgencyUserGetDto;
export type GetApiV1ClientAgenciesByIdApiArg = {
    id: string;
};
export type PostApiV1ClientAuthResetPasswordApiResponse =
    /** status 200 Success */ DarxApplicationDtOsCommonResponsesDtOsGeneralResponse601DarxApplicationDtOsAuthDtOsResetTokenEmailResponseDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull;
export type PostApiV1ClientAuthResetPasswordApiArg = {
    darxApplicationDtOsAuthDtOsResetPasswordDto: DarxApplicationDtOsAuthDtOsResetPasswordDto;
};
export type GetApiV1ClientAuthVerifyPasswordResetTokenApiResponse =
    /** status 200 Success */ DarxApplicationDtOsCommonResponsesDtOsBaseReponse;
export type GetApiV1ClientAuthVerifyPasswordResetTokenApiArg = {
    userId?: string;
    resetToken?: string;
};
export type PostApiV1ClientAuthUpdatePasswordAsyncApiResponse =
    /** status 200 Success */ DarxApplicationDtOsCommonResponsesDtOsBaseReponse;
export type PostApiV1ClientAuthUpdatePasswordAsyncApiArg = {
    darxApplicationDtOsAuthDtOsUpdatePasswordDto: DarxApplicationDtOsAuthDtOsUpdatePasswordDto;
};
export type GetApiV1ClientAuthRefreshTokenLoginAsyncApiResponse =
    /** status 200 Success */ DarxApplicationDtOsTokenDtOsTokenResponseDto;
export type GetApiV1ClientAuthRefreshTokenLoginAsyncApiArg = {
    refreshToken?: string;
};
export type GetApiV1ClientBlogsApiResponse =
    /** status 200 Success */ DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsBlogDtOsBlogGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull;
export type GetApiV1ClientBlogsApiArg = {
    pageNumber?: number;
    pageSize?: number;
    isPaginated?: boolean;
};
export type GetApiV1ClientBuildingTypesApiResponse =
    /** status 200 Success */ DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsBuildingTypeDtOsBuildingTypeGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull;
export type GetApiV1ClientBuildingTypesApiArg = {
    pageNumber?: number;
    pageSize?: number;
    isPaginated?: boolean;
};
export type GetApiV1ClientCitiesApiResponse =
    /** status 200 Success */ DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsCityDtOsCityGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull;
export type GetApiV1ClientCitiesApiArg = {
    pageNumber?: number;
    pageSize?: number;
    isPaginated?: boolean;
    countryId?: string;
};
export type GetApiV1ClientCountriesApiResponse =
    /** status 200 Success */ DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsCountryDtOsCountryGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull;
export type GetApiV1ClientCountriesApiArg = {
    pageNumber?: number;
    pageSize?: number;
    isPaginated?: boolean;
};
export type GetApiV1ClientDistrictsApiResponse =
    /** status 200 Success */ DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsDistrictDtOsDistrictGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull;
export type GetApiV1ClientDistrictsApiArg = {
    pageNumber?: number;
    pageSize?: number;
    isPaginated?: boolean;
    cityId?: string;
};
export type PostApiV1ClientIdentityRegisterApiResponse = unknown;
export type PostApiV1ClientIdentityRegisterApiArg = {
    darxApplicationDtOsIdentityDtOsRegisterDto: DarxApplicationDtOsIdentityDtOsRegisterDto;
};
export type PutApiV1ClientIdentityUpdateApiResponse = unknown;
export type PutApiV1ClientIdentityUpdateApiArg = {
    body: {
        Name?: string;
        Surname?: string;
        Email?: string;
        PhoneNumber?: string;
        CompanyName?: string;
        OldPassword?: string;
        Password?: string;
        ConfirmedPassword?: string;
        File?: Blob;
    };
};
export type GetApiV1ClientIdentityGetCurrentUserApiResponse = unknown;
export type GetApiV1ClientIdentityGetCurrentUserApiArg = void;
export type PostApiV1ClientIdentityLoginApiResponse = unknown;
export type PostApiV1ClientIdentityLoginApiArg = {
    darxApplicationDtOsIdentityDtOsLoginDto: DarxApplicationDtOsIdentityDtOsLoginDto;
};
export type GetApiV1ClientLanguagesApiResponse =
    /** status 200 Success */ DarxApplicationDtOsCommonResponsesDtOsGeneralResponse601SystemCollectionsGenericIEnumerable601DarxApplicationDtOsLanguageDtOsLanguageGetDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull20SystemPrivateCoreLib20Version800020CultureNeutral20PublicKeyToken7Cec85D7Bea7798E;
export type GetApiV1ClientLanguagesApiArg = void;
export type GetApiV1ClientMetrosApiResponse =
    /** status 200 Success */ DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsMetroDtOsMetroGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull;
export type GetApiV1ClientMetrosApiArg = {
    pageNumber?: number;
    pageSize?: number;
    isPaginated?: boolean;
    cityId?: string;
};
export type GetApiV1ClientResidentialComplexesApiResponse =
    /** status 200 Success */ DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsResidentialComplexDtOsResidentialComplexGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull;
export type GetApiV1ClientResidentialComplexesApiArg = {
    pageNumber?: number;
    pageSize?: number;
    isPaginated?: boolean;
};
export type GetApiV1ClientResidentialComplexesByIdApiResponse =
    /** status 200 Success */ DarxApplicationDtOsResidentialComplexDtOsResidentialComplexGetDto;
export type GetApiV1ClientResidentialComplexesByIdApiArg = {
    id: string;
};
export type GetApiV1ClientSlidersApiResponse =
    /** status 200 Success */ DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsSliderDtOsSliderGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull;
export type GetApiV1ClientSlidersApiArg = {
    pageNumber?: number;
    pageSize?: number;
    isPaginated?: boolean;
};
export type GetApiV1ClientSpecificationsApiResponse =
    /** status 200 Success */ DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsSpecificationDtOsSpecificationGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull;
export type GetApiV1ClientSpecificationsApiArg = {
    pageNumber?: number;
    pageSize?: number;
    isPaginated?: boolean;
};
export type DarxApplicationDtOsAdsDtOsAdsImageGetDto = {
    id?: string;
    image?: string | null;
    index?: number;
    rotate?: number;
};
export type DarxDomainEnumsMaintenanceStatus = 0 | 1 | 2;
export type DarxDomainEnumsAdvertOwnerType = 0 | 1;
export type DarxDomainEnumsAdvertType = 0 | 1;
export type DarxDomainEnumsPropertyType = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type DarxDomainEnumsDocumentStatus = 0 | 1;
export type DarxDomainEnumsMortgageStatus = 0 | 1;
export type DarxDomainEnumsPriceType = 0 | 1;
export type DarxDomainEnumsAdsStatus = 0 | 1 | 2 | 3;
export type DarxDomainEnumsBuildingType = 0 | 1 | 2;
export type DarxApplicationDtOsCountryDtOsCountryDictionaryGetAllDto = {
    id?: string;
    name?: string | null;
    languageId?: string;
};
export type DarxApplicationDtOsCountryDtOsCountryGetDto = {
    id?: string;
    image?: string | null;
    countryDictionaryGetAllDtos?: DarxApplicationDtOsCountryDtOsCountryDictionaryGetAllDto[] | null;
};
export type DarxApplicationDtOsCityDtOsCityDictionaryGetAllDto = {
    id?: string;
    name?: string | null;
    languageId?: string;
};
export type DarxApplicationDtOsCityDtOsCityGetDto = {
    id?: string;
    countryId?: string;
    cityDictionaryGetAllDtos?: DarxApplicationDtOsCityDtOsCityDictionaryGetAllDto[] | null;
};
export type DarxApplicationDtOsDistrictDtOsDistrictDictionaryGetAllDto = {
    id?: string;
    name?: string | null;
    languageId?: string;
};
export type DarxApplicationDtOsDistrictDtOsDistrictGetDto = {
    id?: string;
    cityId?: string;
    districtDictionaryGetAllDtos?:
        | DarxApplicationDtOsDistrictDtOsDistrictDictionaryGetAllDto[]
        | null;
};
export type DarxApplicationDtOsMetroDtOsMetroDictionaryGetAllDto = {
    id?: string;
    name?: string | null;
    languageId?: string;
};
export type DarxApplicationDtOsMetroDtOsMetroGetDto = {
    id?: string;
    cityId?: string;
    metroDictionaryGetAllDtos?: DarxApplicationDtOsMetroDtOsMetroDictionaryGetAllDto[] | null;
};
export type DarxApplicationDtOsAdsDtOsAdsGetAllDto = {
    id?: string;
    images?: DarxApplicationDtOsAdsDtOsAdsImageGetDto[] | null;
    districtOrMetro?: string | null;
    maintenanceStatus?: DarxDomainEnumsMaintenanceStatus;
    price?: number | null;
    roomCount?: number | null;
    propertyArea?: number | null;
    propertyFloor?: number | null;
    latitude?: string | null;
    longitude?: string | null;
    date?: string;
    appUserId?: string | null;
    ownerImage?: string | null;
    name?: string | null;
    surname?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    propertyLandArea?: number | null;
    buildingFloorCount?: number | null;
    description?: string | null;
    advertOwnerType?: DarxDomainEnumsAdvertOwnerType;
    advertType?: DarxDomainEnumsAdvertType;
    propertyType?: DarxDomainEnumsPropertyType;
    documentStatus?: DarxDomainEnumsDocumentStatus;
    mortgageStatus?: DarxDomainEnumsMortgageStatus;
    priceType?: DarxDomainEnumsPriceType;
    adsStatus?: DarxDomainEnumsAdsStatus;
    buildingType?: DarxDomainEnumsBuildingType;
    country?: DarxApplicationDtOsCountryDtOsCountryGetDto;
    city?: DarxApplicationDtOsCityDtOsCityGetDto;
    district?: DarxApplicationDtOsDistrictDtOsDistrictGetDto;
    metro?: DarxApplicationDtOsMetroDtOsMetroGetDto;
};
export type DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsAdsDtOsAdsGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull =
    {
        items?: DarxApplicationDtOsAdsDtOsAdsGetAllDto[] | null;
        totalItems?: number;
        pageNumber?: number;
        pageSize?: number;
        totalPages?: number;
    };
export type MicrosoftAspNetCoreMvcProblemDetails = {
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;
    [key: string]: any;
};
export type DarxApplicationDtOsAdsDtOsAdsImagePostDto = {
    image?: Blob | null;
    index?: number;
    rotate?: number;
};
export type DarxApplicationDtOsAdsDtOsAdsVideoPostDto = {
    video?: Blob | null;
    index?: number;
};
export type DarxApplicationDtOsAdsDtOsAdsVideoGetDto = {
    id?: string;
    video?: string | null;
    index?: number;
};
export type DarxApplicationDtOsAdsDtOsAdsGetDto = {
    id?: string;
    appUserId?: string | null;
    advertOwnerType?: DarxDomainEnumsAdvertOwnerType;
    advertType?: DarxDomainEnumsAdvertType;
    propertyType?: DarxDomainEnumsPropertyType;
    maintenanceStatus?: DarxDomainEnumsMaintenanceStatus;
    documentStatus?: DarxDomainEnumsDocumentStatus;
    mortgageStatus?: DarxDomainEnumsMortgageStatus;
    priceType?: DarxDomainEnumsPriceType;
    adsStatus?: DarxDomainEnumsAdsStatus;
    propertyArea?: number | null;
    price?: number | null;
    roomCount?: number | null;
    buildingType?: DarxDomainEnumsBuildingType;
    buildingFloorCount?: number | null;
    propertyFloor?: number | null;
    description?: string | null;
    images?: DarxApplicationDtOsAdsDtOsAdsImageGetDto[] | null;
    adsVideos?: DarxApplicationDtOsAdsDtOsAdsVideoGetDto[] | null;
    country?: DarxApplicationDtOsCountryDtOsCountryGetDto;
    city?: DarxApplicationDtOsCityDtOsCityGetDto;
    district?: DarxApplicationDtOsDistrictDtOsDistrictGetDto;
    metro?: DarxApplicationDtOsMetroDtOsMetroGetDto;
    ownerImage?: string | null;
    name?: string | null;
    surname?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    propertyLandArea?: number | null;
    dateForOrder?: string;
    latitude?: string | null;
    longitude?: string | null;
    rejectNote?: string | null;
};
export type DarxApplicationDtOsAdsDtOsAdsImageUpdateDto = {
    existingImageId?: string | null;
    image?: Blob | null;
    index?: number;
    rotate?: number;
};
export type DarxApplicationDtOsAdsDtOsAdsVideoUpdateDto = {
    existingVideoId?: string | null;
    video?: Blob | null;
    index?: number;
};
export type DarxApplicationDtOsAdvertisementDtOsAdvertisementGetAllDto = {
    id?: string;
    url?: string | null;
    image?: string | null;
};
export type DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsAdvertisementDtOsAdvertisementGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull =
    {
        items?: DarxApplicationDtOsAdvertisementDtOsAdvertisementGetAllDto[] | null;
        totalItems?: number;
        pageNumber?: number;
        pageSize?: number;
        totalPages?: number;
    };
export type DarxApplicationDtOsIdentityDtOsAdminAgencyUserGetDto = {
    id?: string | null;
    name?: string | null;
    surname?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    agencyName?: string | null;
    isBlocked?: boolean;
    wrokTime?: string | null;
    adress?: string | null;
    image?: string | null;
};
export type DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsIdentityDtOsAdminAgencyUserGetDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull =
    {
        items?: DarxApplicationDtOsIdentityDtOsAdminAgencyUserGetDto[] | null;
        totalItems?: number;
        pageNumber?: number;
        pageSize?: number;
        totalPages?: number;
    };
export type DarxApplicationDtOsAuthDtOsResetTokenEmailResponseDto = {
    token?: string | null;
    email?: string | null;
    userId?: string | null;
};
export type DarxApplicationDtOsCommonResponsesDtOsGeneralResponse601DarxApplicationDtOsAuthDtOsResetTokenEmailResponseDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull =
    {
        message?: string | null;
        statusCode?: number;
        id?: string;
        id2?: string | null;
        data?: DarxApplicationDtOsAuthDtOsResetTokenEmailResponseDto;
        url?: string | null;
    };
export type DarxApplicationDtOsAuthDtOsResetPasswordDto = {
    email?: string | null;
};
export type DarxApplicationDtOsCommonResponsesDtOsBaseReponse = {
    message?: string | null;
    statusCode?: number;
    id?: string;
    id2?: string | null;
};
export type DarxApplicationDtOsAuthDtOsUpdatePasswordDto = {
    userId?: string | null;
    resetToken?: string | null;
    newPassword?: string | null;
    confirmedNewPassword?: string | null;
};
export type DarxApplicationDtOsTokenDtOsTokenResponseDto = {
    accessToken?: string | null;
    expiration?: string;
    refreshToken?: string | null;
};
export type DarxApplicationDtOsBlogDtOsBlogGetAllDto = {
    id?: string;
    title?: string | null;
    description?: string | null;
    miniDescription?: string | null;
    image?: string | null;
};
export type DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsBlogDtOsBlogGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull =
    {
        items?: DarxApplicationDtOsBlogDtOsBlogGetAllDto[] | null;
        totalItems?: number;
        pageNumber?: number;
        pageSize?: number;
        totalPages?: number;
    };
export type DarxApplicationDtOsBuildingTypeDtOsBuildingTypeGetAllDto = {
    id?: string;
    name?: string | null;
    icon?: string | null;
};
export type DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsBuildingTypeDtOsBuildingTypeGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull =
    {
        items?: DarxApplicationDtOsBuildingTypeDtOsBuildingTypeGetAllDto[] | null;
        totalItems?: number;
        pageNumber?: number;
        pageSize?: number;
        totalPages?: number;
    };
export type DarxApplicationDtOsCityDtOsCityGetAllDto = {
    id?: string;
    name?: string | null;
    countryId?: string;
};
export type DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsCityDtOsCityGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull =
    {
        items?: DarxApplicationDtOsCityDtOsCityGetAllDto[] | null;
        totalItems?: number;
        pageNumber?: number;
        pageSize?: number;
        totalPages?: number;
    };
export type DarxApplicationDtOsCountryDtOsCountryGetAllDto = {
    id?: string;
    name?: string | null;
    image?: string | null;
};
export type DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsCountryDtOsCountryGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull =
    {
        items?: DarxApplicationDtOsCountryDtOsCountryGetAllDto[] | null;
        totalItems?: number;
        pageNumber?: number;
        pageSize?: number;
        totalPages?: number;
    };
export type DarxApplicationDtOsDistrictDtOsDistrictGetAllDto = {
    id?: string;
    name?: string | null;
    cityId?: string;
};
export type DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsDistrictDtOsDistrictGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull =
    {
        items?: DarxApplicationDtOsDistrictDtOsDistrictGetAllDto[] | null;
        totalItems?: number;
        pageNumber?: number;
        pageSize?: number;
        totalPages?: number;
    };
export type DarxApplicationDtOsIdentityDtOsRegisterDto = {
    name?: string | null;
    surname?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    password?: string | null;
    confirmedPassword?: string | null;
};
export type DarxApplicationDtOsIdentityDtOsLoginDto = {
    email?: string | null;
    password?: string | null;
};
export type DarxApplicationDtOsLanguageDtOsLanguageGetDto = {
    id?: string;
    code?: string | null;
    name?: string | null;
};
export type DarxApplicationDtOsCommonResponsesDtOsGeneralResponse601SystemCollectionsGenericIEnumerable601DarxApplicationDtOsLanguageDtOsLanguageGetDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull20SystemPrivateCoreLib20Version800020CultureNeutral20PublicKeyToken7Cec85D7Bea7798E =
    {
        message?: string | null;
        statusCode?: number;
        id?: string;
        id2?: string | null;
        data?: DarxApplicationDtOsLanguageDtOsLanguageGetDto[] | null;
        url?: string | null;
    };
export type DarxApplicationDtOsMetroDtOsMetroGetAllDto = {
    id?: string;
    name?: string | null;
    cityId?: string;
};
export type DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsMetroDtOsMetroGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull =
    {
        items?: DarxApplicationDtOsMetroDtOsMetroGetAllDto[] | null;
        totalItems?: number;
        pageNumber?: number;
        pageSize?: number;
        totalPages?: number;
    };
export type DarxApplicationDtOsResidentialComplexDtOsResidentialComplexImageGetDto = {
    id?: string;
    image?: string | null;
};
export type DarxApplicationDtOsResidentialComplexDtOsResidentialComplexGetAllDto = {
    id?: string;
    title?: string | null;
    minPrice?: number;
    adress?: string | null;
    description?: string | null;
    latitude?: number;
    longitude?: number;
    mainImage?: string | null;
    images?: DarxApplicationDtOsResidentialComplexDtOsResidentialComplexImageGetDto[] | null;
};
export type DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsResidentialComplexDtOsResidentialComplexGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull =
    {
        items?: DarxApplicationDtOsResidentialComplexDtOsResidentialComplexGetAllDto[] | null;
        totalItems?: number;
        pageNumber?: number;
        pageSize?: number;
        totalPages?: number;
    };
export type DarxApplicationDtOsIdentityDtOsUserGetDto = {
    id?: string | null;
    name?: string | null;
    surname?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    agencyName?: string | null;
    isBlocked?: boolean | null;
    balance?: number;
    addCount?: number;
    activeAddCount?: number;
    rejectedAddCount?: number;
    deactiveAddCount?: number;
    image?: string | null;
};
export type DarxDomainEnumsUserType = 0 | 1 | 2 | 3;
export type DarxDomainEntitiesAdsImages = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    adsId?: string;
    ads?: DarxDomainEntitiesAds;
    index?: number;
    image?: string | null;
    rotate?: number;
};
export type DarxDomainEntitiesAdsVideos = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    adsId?: string;
    ads?: DarxDomainEntitiesAds;
    index?: number;
    image?: string | null;
};
export type DarxDomainEntitiesCountryDictionary = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    language?: DarxDomainEntitiesLanguage;
    country?: DarxDomainEntitiesCountry;
    languageId?: string;
    countryId?: string;
    name?: string | null;
};
export type DarxDomainEntitiesCityDictionary = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    language?: DarxDomainEntitiesLanguage;
    city?: DarxDomainEntitiesCity;
    languageId?: string;
    cityId?: string;
    name?: string | null;
};
export type DarxDomainEntitiesMetro = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    cityId?: string;
    city?: DarxDomainEntitiesCity;
    metroDictionaries?: DarxDomainEntitiesMetroDictionary[] | null;
    ads?: DarxDomainEntitiesAds[] | null;
};
export type DarxDomainEntitiesMetroDictionary = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    language?: DarxDomainEntitiesLanguage;
    metro?: DarxDomainEntitiesMetro;
    languageId?: string;
    metroId?: string;
    name?: string | null;
};
export type DarxDomainEntitiesSlider = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    image?: string | null;
    sliderDictionaries?: DarxDomainEntitiesSliderDictionary[] | null;
};
export type DarxDomainEntitiesSliderDictionary = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    title?: string | null;
    description?: string | null;
    languageId?: string;
    language?: DarxDomainEntitiesLanguage;
    sliderId?: string;
    slider?: DarxDomainEntitiesSlider;
};
export type DarxDomainEntitiesBlog = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    image?: string | null;
    blogDictionaries?: DarxDomainEntitiesBlogDictionary[] | null;
};
export type DarxDomainEntitiesBlogDictionary = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    title?: string | null;
    description?: string | null;
    miniDescription?: string | null;
    languageId?: string;
    language?: DarxDomainEntitiesLanguage;
    blogId?: string;
    blog?: DarxDomainEntitiesBlog;
};
export type DarxDomainEntitiesResidentialComplexSpecification = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    value?: string | null;
    residentialComplexId?: string;
    residentialComplex?: DarxDomainEntitiesResidentialComplex;
    specificationId?: string;
    specification?: DarxDomainEntitiesSpecification;
};
export type DarxDomainEntitiesSpecification = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    icon?: string | null;
    specificationDictionaries?: DarxDomainEntitiesSpecificationDictionary[] | null;
    residentialComplexSpecifications?: DarxDomainEntitiesResidentialComplexSpecification[] | null;
};
export type DarxDomainEntitiesSpecificationDictionary = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    language?: DarxDomainEntitiesLanguage;
    specification?: DarxDomainEntitiesSpecification;
    languageId?: string;
    specificationId?: string;
    title?: string | null;
};
export type DarxDomainEntitiesBuildingType = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    icon?: string | null;
    buildingTypeDictionaries?: DarxDomainEntitiesBuildingTypeDictionary[] | null;
};
export type DarxDomainEntitiesBuildingTypeDictionary = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    name?: string | null;
    languageId?: string;
    language?: DarxDomainEntitiesLanguage;
    buildingTypeId?: string;
    buildingType?: DarxDomainEntitiesBuildingType;
};
export type DarxDomainEntitiesLanguage = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    code?: string | null;
    name?: string | null;
    countryDictionaries?: DarxDomainEntitiesCountryDictionary[] | null;
    cityDictionaries?: DarxDomainEntitiesCityDictionary[] | null;
    districtDictionaries?: DarxDomainEntitiesDistrictDictionary[] | null;
    metroDictionaries?: DarxDomainEntitiesMetroDictionary[] | null;
    sliderDictionaries?: DarxDomainEntitiesSliderDictionary[] | null;
    blogDictionaries?: DarxDomainEntitiesBlogDictionary[] | null;
    specificationDictionaries?: DarxDomainEntitiesSpecificationDictionary[] | null;
    buildingTypeDictionaries?: DarxDomainEntitiesBuildingTypeDictionary[] | null;
};
export type DarxDomainEntitiesDistrictDictionary = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    language?: DarxDomainEntitiesLanguage;
    district?: DarxDomainEntitiesDistrict;
    languageId?: string;
    districtId?: string;
    name?: string | null;
};
export type DarxDomainEntitiesDistrict = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    cityId?: string;
    city?: DarxDomainEntitiesCity;
    districtDictionaries?: DarxDomainEntitiesDistrictDictionary[] | null;
    ads?: DarxDomainEntitiesAds[] | null;
};
export type DarxDomainEntitiesCity = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    countryId?: string;
    country?: DarxDomainEntitiesCountry;
    districts?: DarxDomainEntitiesDistrict[] | null;
    metros?: DarxDomainEntitiesMetro[] | null;
    cityDictionaries?: DarxDomainEntitiesCityDictionary[] | null;
    ads?: DarxDomainEntitiesAds[] | null;
};
export type DarxDomainEntitiesCountry = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    flag?: string | null;
    cities?: DarxDomainEntitiesCity[] | null;
    countryDictionaries?: DarxDomainEntitiesCountryDictionary[] | null;
    ads?: DarxDomainEntitiesAds[] | null;
};
export type DarxDomainEntitiesAds = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    advertOwnerType?: DarxDomainEnumsAdvertOwnerType;
    advertType?: DarxDomainEnumsAdvertType;
    propertyType?: DarxDomainEnumsPropertyType;
    maintenanceStatus?: DarxDomainEnumsMaintenanceStatus;
    documentStatus?: DarxDomainEnumsDocumentStatus;
    mortgageStatus?: DarxDomainEnumsMortgageStatus;
    priceType?: DarxDomainEnumsPriceType;
    adsStatus?: DarxDomainEnumsAdsStatus;
    propertyArea?: number | null;
    price?: number | null;
    roomCount?: number | null;
    buildingType?: DarxDomainEnumsBuildingType;
    buildingFloorCount?: number | null;
    propertyFloor?: number | null;
    description?: string | null;
    images?: DarxDomainEntitiesAdsImages[] | null;
    adsVideos?: DarxDomainEntitiesAdsVideos[] | null;
    countryId?: string;
    country?: DarxDomainEntitiesCountry;
    cityId?: string;
    city?: DarxDomainEntitiesCity;
    districtId?: string;
    district?: DarxDomainEntitiesDistrict;
    metroId?: string | null;
    metro?: DarxDomainEntitiesMetro;
    name?: string | null;
    surname?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    propertyLandArea?: number | null;
    appUserId?: string | null;
    appUser?: DarxDomainEntitiesAppUser;
    dateForOrder?: string;
    latitude?: string | null;
    longitude?: string | null;
    rejectNote?: string | null;
};
export type DarxDomainEntitiesAppUser = {
    id?: string | null;
    userName?: string | null;
    normalizedUserName?: string | null;
    email?: string | null;
    normalizedEmail?: string | null;
    emailConfirmed?: boolean;
    passwordHash?: string | null;
    securityStamp?: string | null;
    concurrencyStamp?: string | null;
    phoneNumber?: string | null;
    phoneNumberConfirmed?: boolean;
    twoFactorEnabled?: boolean;
    lockoutEnd?: string | null;
    lockoutEnabled?: boolean;
    accessFailedCount?: number;
    name?: string | null;
    surname?: string | null;
    agencyName?: string | null;
    isBlocked?: boolean;
    isDeleted?: boolean;
    refreshToken?: string | null;
    image?: string | null;
    userType?: DarxDomainEnumsUserType;
    wrokTime?: string | null;
    adress?: string | null;
    refreshTokenExpirationDate?: string;
    residentialComplexes?: DarxDomainEntitiesResidentialComplex[] | null;
    ads?: DarxDomainEntitiesAds[] | null;
};
export type DarxDomainEntitiesResidentialComplex = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    mainImage?: string | null;
    title?: string | null;
    minPrice?: number;
    adress?: string | null;
    description?: string | null;
    latitude?: number;
    longitude?: number;
    appUserId?: string;
    appUser?: DarxDomainEntitiesAppUser;
    images?: DarxDomainEntitiesResidentialComplexImage[] | null;
    residentialComplexSpecifications?: DarxDomainEntitiesResidentialComplexSpecification[] | null;
};
export type DarxDomainEntitiesResidentialComplexImage = {
    id?: string;
    isDeleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    image?: string | null;
    residentialComplexId?: string;
    residentialComplex?: DarxDomainEntitiesResidentialComplex;
};
export type DarxApplicationDtOsSpecificationDtOsSpecificationDictionaryGetDto = {
    id?: string;
    title?: string | null;
    languageId?: string;
};
export type DarxApplicationDtOsResidentialComplexDtOsResidentialComplexSpecificationGetDto = {
    id?: string;
    value?: string | null;
    labels?: DarxApplicationDtOsSpecificationDtOsSpecificationDictionaryGetDto[] | null;
    icon?: string | null;
    specificationId?: string;
};
export type DarxApplicationDtOsResidentialComplexDtOsResidentialComplexGetDto = {
    id?: string;
    title?: string | null;
    minPrice?: number;
    adress?: string | null;
    description?: string | null;
    latitude?: number;
    longitude?: number;
    mainImage?: string | null;
    agentId?: string;
    agent?: DarxApplicationDtOsIdentityDtOsUserGetDto;
    images?: DarxDomainEntitiesResidentialComplexImage[] | null;
    residentialComplexSpecificationGetDtos?:
        | DarxApplicationDtOsResidentialComplexDtOsResidentialComplexSpecificationGetDto[]
        | null;
};
export type DarxApplicationDtOsSliderDtOsSliderGetAllDto = {
    id?: string;
    title?: string | null;
    description?: string | null;
    image?: string | null;
};
export type DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsSliderDtOsSliderGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull =
    {
        items?: DarxApplicationDtOsSliderDtOsSliderGetAllDto[] | null;
        totalItems?: number;
        pageNumber?: number;
        pageSize?: number;
        totalPages?: number;
    };
export type DarxApplicationDtOsSpecificationDtOsSpecificationGetAllDto = {
    id?: string;
    title?: string | null;
    icon?: string | null;
};
export type DarxApplicationDtOsCommonResponsesDtOsPaginatedResponse601DarxApplicationDtOsSpecificationDtOsSpecificationGetAllDto20DarxApplication20Version100020CultureNeutral20PublicKeyTokenNull =
    {
        items?: DarxApplicationDtOsSpecificationDtOsSpecificationGetAllDto[] | null;
        totalItems?: number;
        pageNumber?: number;
        pageSize?: number;
        totalPages?: number;
    };
export const {
    useGetApiV1ClientAdsQuery,
    usePostApiV1ClientAdsMutation,
    useGetApiV1ClientAdsGetAllCurrentUserAdsQuery,
    useGetApiV1ClientAdsByIdQuery,
    usePutApiV1ClientAdsByIdMutation,
    useDeleteApiV1ClientAdsByIdMutation,
    useGetApiV1ClientAdvertisementsQuery,
    useGetApiV1ClientAgenciesQuery,
    useGetApiV1ClientAgenciesByIdQuery,
    usePostApiV1ClientAuthResetPasswordMutation,
    useGetApiV1ClientAuthVerifyPasswordResetTokenQuery,
    usePostApiV1ClientAuthUpdatePasswordAsyncMutation,
    useGetApiV1ClientAuthRefreshTokenLoginAsyncQuery,
    useGetApiV1ClientBlogsQuery,
    useGetApiV1ClientBuildingTypesQuery,
    useGetApiV1ClientCitiesQuery,
    useGetApiV1ClientCountriesQuery,
    useGetApiV1ClientDistrictsQuery,
    usePostApiV1ClientIdentityRegisterMutation,
    usePutApiV1ClientIdentityUpdateMutation,
    useGetApiV1ClientIdentityGetCurrentUserQuery,
    usePostApiV1ClientIdentityLoginMutation,
    useGetApiV1ClientLanguagesQuery,
    useGetApiV1ClientMetrosQuery,
    useGetApiV1ClientResidentialComplexesQuery,
    useGetApiV1ClientResidentialComplexesByIdQuery,
    useGetApiV1ClientSlidersQuery,
    useGetApiV1ClientSpecificationsQuery,
} = injectedRtkApi;
