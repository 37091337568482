import React, { useEffect } from 'react';
import CreateAdvertSteps from './components/steps';
import { useMobileTab } from 'hooks/use-mobile-tab';
import CreateAdverInfoSection from './components/info';
import { useTranslation } from 'react-i18next';

const CreateAdvertPage = () => {
    const { t } = useTranslation();
    const { onTabChange } = useMobileTab();

    useEffect(() => {
        onTabChange('createAdvert');
    }, []);

    return (
        <>
            <div className="absolute top-0 left-0 w-full h-[900px] bg-home"></div>
            <div className="w-full px-5 md:px-0 md:w-[925px] lg:w-[1400px] md:mx-auto z-[1] relative">
                <h1 className="text-txt text-xl md:text-4xl md:text-left text-center mb-5 md:mb-10 font-bold">
                    {t('advert.create-title')}
                </h1>
                <div className="grid gap-2 md:grid-cols-[680px,1fr] lg:grid-cols-[1060px,1fr] h-fit mt-4 md:mt-8">
                    <CreateAdvertSteps />
                    <CreateAdverInfoSection />
                </div>
            </div>
        </>
    );
};

export default CreateAdvertPage;
