import { cn } from 'lib/utils';
import React from 'react';

interface CheckboxProps {
    value: boolean;
    size: number;
    onChange: () => void;
}

const Checkbox = ({ value, onChange, size }: CheckboxProps) => {
    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
                onChange();
            }}
            className={cn(
                'border-2 cursor-pointer p-[1.5px]',
                value ? 'border-brend' : 'border-dark-20 md:group-hover:border-brend/30',
            )}
            style={{ width: size, height: size }}
        >
            <div
                className={cn(
                    'w-full h-full cursor-pointer',
                    value ? 'bg-brend' : ' md:group-hover:bg-brend/30',
                )}
            ></div>
        </div>
    );
};

export default Checkbox;
