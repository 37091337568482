import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { cn } from 'lib/utils';
import React, { useEffect, useState } from 'react';
import { IOption } from 'types';

interface CustomSelectOutlineProps {
    selectedOption?: IOption;
    options: IOption[];
    onChange: (option: IOption) => void;
    inputClassName?: string;
    iconClassName?: string;
    dropDownClassName?: string;
    dropDownOptionClassName?: string;
}

const CustomSelectOutline = ({
    selectedOption,
    options,
    onChange,
    iconClassName,
    inputClassName,
    dropDownClassName,
    dropDownOptionClassName,
}: CustomSelectOutlineProps) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const onOutsideClick = () => {
            setIsOpen(false);
        };

        window.addEventListener('click', onOutsideClick);

        return () => window.removeEventListener('click', onOutsideClick);
    }, []);

    return (
        <div onClick={(e) => e.stopPropagation()} className="relative cursor-pointer text-xs ">
            <div
                onClick={() => setIsOpen((prev) => !prev)}
                className={cn(
                    ' min-w-[50px] flex gap-x-0.5 justify-between items-center px-1.5 py-0.5 border rounded-sm',
                    inputClassName,
                )}
            >
                {selectedOption?.label}
                <ArrowDownIcon
                    className={cn('duration-150', iconClassName, isOpen && 'rotate-180')}
                />
            </div>
            {isOpen && (
                <div
                    className={cn(
                        'absolute w-full top-5 left-0 border border-t-0',
                        dropDownClassName,
                    )}
                >
                    {options.map(
                        (option) =>
                            option.value !== selectedOption?.value && (
                                <div
                                    key={option.value}
                                    onClick={() => {
                                        setIsOpen(false);
                                        onChange(option);
                                    }}
                                    className={cn(
                                        'h-fit px-1.5 py-0.5 cursor-pointer hover:opacity-80 duration-100',
                                        dropDownOptionClassName,
                                    )}
                                >
                                    {option.label}
                                </div>
                            ),
                    )}
                </div>
            )}
        </div>
    );
};

export default CustomSelectOutline;
