import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { cn } from 'lib/utils';
import { useEffect, useState } from 'react';
import { IOption } from 'types';

interface CustomSelectUnderlineProps {
    selectedOption: IOption;
    options: IOption[];
    onChange: (option: IOption) => void;
    dropdownTriggerClassName?: string;
    dropdownOptionClassName?: string;
}

const CustomSelectUnderline = ({
    selectedOption,
    options,
    onChange,
    dropdownTriggerClassName,
    dropdownOptionClassName,
}: CustomSelectUnderlineProps) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const onOutsideClick = () => {
            setIsOpen(false);
        };
        window.addEventListener('click', onOutsideClick);

        return () => window.removeEventListener('click', onOutsideClick);
    }, []);

    return (
        <div
            onClick={(e) => e.stopPropagation()}
            className="relative cursor-pointer text-[20px] md:text-[24px] font-medium"
        >
            <div
                onClick={() => setIsOpen((prev) => !prev)}
                className={cn(
                    'min-w-[50px] flex gap-x-[19px] items-center pb-4 border-b border-dark-60',
                    dropdownTriggerClassName,
                )}
            >
                <ArrowDownIcon
                    className={cn('duration-150 fill-txt w-[10px] h-[6px]', isOpen && 'rotate-180')}
                />
                {selectedOption.label}
            </div>
            {isOpen && (
                <div
                    className="absolute w-full top- left-0 border-[0.5px] border-t-0 border-black py-3 bg-white"
                    style={{
                        boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.15)',
                    }}
                >
                    {options.map(
                        (option) =>
                            option.value !== selectedOption.value && (
                                <div
                                    key={option.value}
                                    onClick={() => {
                                        setIsOpen(false);
                                        onChange(option);
                                    }}
                                    className={
                                        (cn(
                                            'px-5 py-0.5 cursor-pointer hover:opacity-80 duration-100',
                                        ),
                                        dropdownOptionClassName)
                                    }
                                >
                                    {option.label}
                                </div>
                            ),
                    )}
                </div>
            )}
        </div>
    );
};

export default CustomSelectUnderline;
