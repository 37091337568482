import Login from 'components/login';
import { useMobileTab } from 'hooks/use-mobile-tab';
import React from 'react';

const MobileLoginTab = () => {
    const { onTabChange } = useMobileTab();
    return (
        <div className="flex justify-center md:hidden w-full">
            <Login
                onRegisterOpen={() => onTabChange('register')}
                onLoginClose={() => onTabChange('home')}
            />
        </div>
    );
};

export default MobileLoginTab;
