import CustomSelectPrimary from 'components/common/custom-select/primary';
import Range from 'components/common/range';

import { cn } from 'lib/utils';
import { useMobileTab } from 'hooks/use-mobile-tab';
import React, { useState } from 'react';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import CheckOption from './option';

import { useModal } from 'hooks/use-modal';
import ButtonPrimary from 'components/common/button/primary';
import { RenderIf } from 'components/common/render-if';
import { useTranslation } from 'react-i18next';

const OPTIONS: { value: string; label: string; type: 'checkbox' | 'simple' }[] = [
    {
        value: 'Bir olmasın',
        label: 'Bir olmasın',
        type: 'checkbox',
    },
    {
        value: 'Axrıncı olmasın',
        label: 'Axrıncı olmasın',
        type: 'checkbox',
    },
    {
        value: 'Yalnız ən üst',
        label: 'Yalnız ən üst',
        type: 'checkbox',
    },
    {
        value: 'Təmirli',
        label: 'Təmirli',
        type: 'checkbox',
    },
    {
        value: 'Cıxarış var',
        label: 'Cıxarış var',
        type: 'checkbox',
    },
    {
        value: 'İpoteka',
        label: 'İpoteka',
        type: 'checkbox',
    },
    {
        value: 'Rayon, Qəsəbə',
        label: 'Rayon, Qəsəbə',
        type: 'simple',
    },
    {
        value: 'Metrostansiyalar',
        label: 'Metrostansiyalar',
        type: 'simple',
    },
    {
        value: 'Nişangahlar',
        label: 'Nişangahlar',
        type: 'simple',
    },
];

type Props = {
    advanced?: boolean;
};
const Filter = ({ advanced = false }: Props) => {
    const { t } = useTranslation();
    const { tab } = useMobileTab();
    const [selectedCategory, setSelectedCategory] = useState<'sale' | 'rent'>('sale');
    const { onClose, onOpen } = useModal();

    return (
        <div className="px-5 sm:px-0">
            <div className="flex flex-col md:flex-row">
                <div
                    className={cn(
                        'w-full pb-3.5',
                        advanced && 'pb-[30px] md:max-w-[350px] md:pr-4 md:pt-[22px]',
                    )}
                >
                    <RenderIf condition={tab === 'search' || !advanced}>
                        <div className="border-b mb-4 border-dark-20 flex justify-between px-5 text-[#757374] font-semibold tracking-[0.64px]">
                            <button
                                className={cn(
                                    'grow pb-5 md:py-5 leading-[19px] border-b-2 border-transparent hover:bg-primary/10 duration-100',
                                    selectedCategory === 'sale' && 'border-primary text-primary',
                                )}
                                onClick={() => setSelectedCategory('sale')}
                            >
                                {t('general.sale')}
                            </button>
                            <button
                                className={cn(
                                    'grow pb-5 md:py-5 leading-[19px] border-b-2 border-transparent hover:bg-primary/10 duration-100',
                                    selectedCategory === 'rent' && 'border-primary text-primary',
                                )}
                                onClick={() => setSelectedCategory('rent')}
                            >
                                {t('general.rent')}
                            </button>
                        </div>
                    </RenderIf>
                    <RenderIf condition={!advanced}>
                        <div className="mt-[50px]" />
                    </RenderIf>
                    <div className={cn('flex flex-col gap-y-[14px]', !advanced && 'px-5')}>
                        <CustomSelectPrimary placeholder="Bütün elanlar" />
                        <CustomSelectPrimary placeholder="Əmlak tipi" />
                        <CustomSelectPrimary placeholder="Elan Nömrəsi" />
                    </div>
                </div>
                <RenderIf condition={advanced}>
                    <div className="w-full">
                        <div className="w-full text-sm grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-5 gap-y-3">
                            <Range label="Qiymət (AZN)" />
                            <Range label="Sahəsi, m2" />
                            <Range label="Mərtəbə" />
                        </div>
                        <div className="grid grid-cols-2 md:grid-cols-3 gap-x-2.5 lg:gap-x-5 gap-y-4 mt-5 text-[15px] lg:text-base">
                            {OPTIONS.map((option) => (
                                <CheckOption key={option.value} option={option} />
                            ))}
                        </div>
                    </div>
                </RenderIf>
            </div>
            <RenderIf condition={!advanced}>
                <button
                    onClick={() => onOpen(`${selectedCategory}-filter`)}
                    className="hidden md:flex items-center gap-x-4 font-bold text-brend hover:opacity-80 duration-100 cursor-pointer pl-5"
                >
                    <FilterIcon />
                    {t('filter.advanced-search')}
                </button>
            </RenderIf>
            <div className={cn('flex justify-between items-center mt-8', !advanced && 'px-5')}>
                <RenderIf condition={advanced}>
                    <button
                        onClick={onClose}
                        className="hidden md:flex items-center gap-x-4 font-bold text-brend hover:opacity-80 duration-100 cursor-pointer"
                    >
                        {t('filter.hide')}
                        <ArrowRightIcon className="fill-brend w-[10px] h-[10px]" />
                    </button>
                </RenderIf>
                <div
                    className={cn(
                        'flex w-full justify-between md:justify-start md:gap-x-10',
                        advanced && 'md:w-fit',
                    )}
                >
                    <RenderIf condition={advanced}>
                        <button className="flex items-center gap-x-4 font-bold text-brend hover:opacity-80 duration-100 cursor-pointer">
                            {t('filter.reset')}
                        </button>
                    </RenderIf>
                    <div className={cn(!advanced && 'w-full')}>
                        <ButtonPrimary
                            disabled={false}
                            text={t('filter.search')}
                            icon={<SearchIcon />}
                            className={cn(advanced ? 'w-32 md:w-56' : 'w-full')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Filter;
