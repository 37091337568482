import { useLanguage } from 'hooks/use-language';
import { onLanguageChange } from 'lib/i18n';
import { cn } from 'lib/utils';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetApiV1ClientLanguagesQuery } from 'service/api/allApis';

const ChangeLanguage = () => {
    const { '1': i18n, t } = useTranslation();
    const { onLanguageIdChange } = useLanguage();
    const { data } = useGetApiV1ClientLanguagesQuery();
    const languages = data?.data || [];
    const options = languages.map((language) => ({
        value: language.code ?? '',
        label: language.name ?? '',
    }));

    useEffect(() => {
        const option = languages?.find((language) => language.code === i18n.language);

        if (option) onLanguageIdChange(option?.id ?? null, option?.code ?? '');
    }, [i18n.language, languages]);

    return (
        <div className="flex gap-3">
            {options.map((option) => (
                <button
                    onClick={() => {
                        onLanguageChange(option.value);
                    }}
                    className={cn(
                        'px-2 py-0.5 rounded-sm border border-white duration-100',
                        i18n.language === option.value && 'bg-white text-primary',
                    )}
                >
                    {option.label}
                </button>
            ))}
        </div>
    );
};

export default ChangeLanguage;
