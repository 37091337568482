import { ReactComponent as BookmarkIcon } from 'assets/icons/advert-bookmark.svg';
import { ReactComponent as FloorIcon } from 'assets/icons/floor.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { ReactComponent as RoomIcon } from 'assets/icons/room.svg';
import { ReactComponent as VolumeIcon } from 'assets/icons/volume.svg';
import { cn, formatDynamicDate, formatPrice } from 'lib/utils';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import AdvertBadge from '../advert-badge';
import { Skeleton } from '../skeleton';
import { DarxApplicationDtOsAdsDtOsAdsGetAllDto } from 'service/api/allApis';
import {
    ADVERT_TYPE,
    MAINTENANCE_STATUS,
    MORTGAGE_STATUS,
    PRICE_TYPE,
    PROPERTY_TYPE,
} from 'lib/constants';
import { useTranslation } from 'react-i18next';
import { RenderIf } from '../render-if';
import { useLanguage } from 'hooks/use-language';
import moment from 'moment';

interface AdvertCardProps {
    advert: DarxApplicationDtOsAdsDtOsAdsGetAllDto;
}

const AdvertCard = ({ advert }: AdvertCardProps) => {
    const [bookmarked, setBookmarked] = useState(false);
    const { selectedLanguageId, selectedLanguageCode } = useLanguage();
    const [isFullPrice, setIsFullPrice] = useState(true);
    const { t } = useTranslation();

    const country = useMemo(
        () =>
            selectedLanguageId
                ? advert.country?.countryDictionaryGetAllDtos?.find(
                      (i) => i.languageId === selectedLanguageId,
                  )?.name
                : advert.country?.countryDictionaryGetAllDtos?.[0].name,
        [selectedLanguageId, advert.country?.countryDictionaryGetAllDtos],
    );
    const city = useMemo(
        () =>
            selectedLanguageId
                ? advert.city?.cityDictionaryGetAllDtos?.find(
                      (i) => i.languageId === selectedLanguageId,
                  )?.name
                : advert.city?.cityDictionaryGetAllDtos?.[0].name,
        [selectedLanguageId, advert.country?.countryDictionaryGetAllDtos],
    );

    const district = useMemo(
        () =>
            advert.district?.districtDictionaryGetAllDtos?.find(
                (i) => !selectedLanguageId || i.languageId === selectedLanguageId,
            )?.name,
        [selectedLanguageId, advert.country?.countryDictionaryGetAllDtos],
    );
    const metro = useMemo(
        () =>
            advert.metro?.metroDictionaryGetAllDtos?.find(
                (i) => !selectedLanguageId || i.languageId === selectedLanguageId,
            )?.name,
        [selectedLanguageId, advert.country?.countryDictionaryGetAllDtos],
    );
    const advertTypeTitle =
        advert.advertType === ADVERT_TYPE.RENT ? t('general.rent') : t('general.sale');
    const mortgageStatusTitle =
        advert.mortgageStatus === MORTGAGE_STATUS.YES
            ? t('advert.with-mortgage')
            : t('advert.without-mortgage');
    const priceType =
        advert.priceType === PRICE_TYPE.MONTHLY ? t('advert.monthly') : t('advert.daily');
    const maintenanceStatus =
        advert.maintenanceStatus === MAINTENANCE_STATUS.FULL
            ? t('advert.full-repair')
            : advert.maintenanceStatus === MAINTENANCE_STATUS.AVERAGE
            ? t('advert.average-repair')
            : t('advert.without-repair');
    const showMortgageStatus = advert.advertType === ADVERT_TYPE.SALE;
    const showRoomCount =
        [
            PROPERTY_TYPE.NEW_BUILDING,
            PROPERTY_TYPE.OLD_BUILDING,
            PROPERTY_TYPE.YARD_HOUSE,
            PROPERTY_TYPE.COMMERCIAL,
        ].includes(advert.propertyType!) && !!advert.roomCount;
    const showFloorInfo = [
        PROPERTY_TYPE.NEW_BUILDING,
        PROPERTY_TYPE.OLD_BUILDING,
        PROPERTY_TYPE.COMMERCIAL,
    ].includes(advert.propertyType!);
    const showMaintenance = [
        PROPERTY_TYPE.NEW_BUILDING,
        PROPERTY_TYPE.OLD_BUILDING,
        PROPERTY_TYPE.OFFICE,
        PROPERTY_TYPE.YARD_HOUSE,
        PROPERTY_TYPE.COMMERCIAL,
    ].includes(advert.propertyType!);

    const title = district || metro || city;
    const address = `${country}, ${city}`;
    const price = isFullPrice ? advert.price : Math.round(advert.price! / advert.propertyArea!);

    return (
        <Link to={`/advert/${advert.id}`}>
            <div className="min-h-[254px] md:min-h-[417px] py-1.5 sm:py-2.5 md:py-5 px-2 sm:px-3.5 border border-brend-20 rounded-[15px] bg-white cursor-pointer hover:shadow-advert-card duration-100">
                <div className="w-full h-[120px] md:h-[190px] relative mb-2 md:mb-5">
                    <img
                        src={advert.images?.[0].image ?? ''}
                        alt="Advert"
                        className="w-[calc(100%-4px)] h-full object-cover object-center ml-auto rounded-[15px]"
                    />
                    <AdvertBadge
                        backgroundColor="#FFA920"
                        title={advertTypeTitle}
                        containerClassName="top-[20px] left-0"
                    />
                    <RenderIf condition={showMortgageStatus}>
                        <AdvertBadge
                            backgroundColor="#2087FF"
                            title={mortgageStatusTitle}
                            containerClassName="top-[34px] md:top-[45px] left-0"
                        />
                    </RenderIf>
                    <BookmarkIcon
                        onClick={(e) => {
                            e.preventDefault();
                            setBookmarked((prev) => !prev);
                        }}
                        className={cn(
                            'w-7 h-7 md:w-fit md:h-fit absolute right-[-1px] top-[-1px] cursor-pointer duration-200',
                            bookmarked && 'fill-primary',
                        )}
                    />
                </div>
                <h3 className="text-txt text-sm md:text-[20px] font-bold">{title}</h3>
                <p className="flex gap-x-2 items-center group w-fit cursor-pointer my-1.5 sm:my-[9px] md:mt-2 md:mb-4">
                    <LocationIcon className="fill-txt-40 w-[7px] h-[10px] md:w-[11.101px] md:h-[17px] group-hover:fill-txt/60 duration-100" />
                    <span className="text-[10px] md:text-base text-txt-40 font-semibold underline group-hover:text-txt/60 duration-100">
                        {address}
                    </span>
                </p>
                <div className="flex flex-wrap items-center  min-h-[28px]">
                    <span className="text-brend text-xs sm:text-[14px] md:text-[23px] font-semibold">
                        {formatPrice(price)} AZN
                    </span>
                    <RenderIf condition={advert.advertType === ADVERT_TYPE.RENT}>
                        <p className="lowercase text-txt-60 text-[8px] sm:text-[10px] md:text-[16px] font-bold translate-y-0.5">
                            /{priceType}
                        </p>
                    </RenderIf>
                    <RenderIf condition={advert.advertType === ADVERT_TYPE.SALE}>
                        <div className="flex text-[8px] md:text-sm ml-auto">
                            <span
                                className={cn(
                                    'flex items-center h-4 md:h-7  rounded-l-[15px] pl-1.5 sm:pl-3 md:pl-[15px] pr-1 sm:pr-2.5  font-medium',
                                    isFullPrice ? 'bg-brend text-white' : 'bg-dark-05 text-txt-40',
                                )}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsFullPrice(true);
                                }}
                            >
                                tam
                            </span>
                            <span
                                className={cn(
                                    'flex items-center h-4 md:h-7  rounded-r-[15px] pr-1.5 sm:pr-3 md:pr-[15px] pl-1 sm:pl-2.5  font-medium',
                                    isFullPrice ? 'bg-dark-05 text-txt-40' : 'bg-brend text-white',
                                )}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsFullPrice(false);
                                }}
                            >
                                1м2
                            </span>
                        </div>
                    </RenderIf>
                </div>
                <div className="flex justify-between items-center my-[9px] md:my-4 text-[10px] md:text-base">
                    <RenderIf condition={showRoomCount}>
                        <div className="flex items-center gap-x-1.5">
                            <RoomIcon className="md:w-fit md:h-fit w-[10px] h-[10px]" />
                            <span className="text-dark-60 font-medium">
                                {advert.roomCount} otaqlı
                            </span>
                        </div>
                    </RenderIf>
                    <div className="flex items-center gap-x-1.5">
                        <VolumeIcon className="fill-dark-60 md:w-fit md:h-fit w-[10px] h-[10px]" />
                        <span className="text-dark-60 font-medium">
                            75 {advert.propertyType === PROPERTY_TYPE.LAND ? 'sot' : 'm²'}
                        </span>
                    </div>
                    <RenderIf condition={showFloorInfo}>
                        <div className="flex items-center gap-x-1.5">
                            <FloorIcon className="fill-dark-60 md:w-fit md:h-fit w-[10px] h-[10px]" />
                            <span className="text-dark-60 font-medium">
                                {advert.propertyFloor}/{advert.buildingFloorCount}
                            </span>
                        </div>
                    </RenderIf>
                </div>
                <div className="flex justify-between text-second-60 font-medium text-[10px] md:text-base">
                    <RenderIf condition={showMaintenance}>
                        <span>{maintenanceStatus}</span>
                    </RenderIf>
                    <span className="ml-auto">
                        {formatDynamicDate(advert.date!, selectedLanguageCode!)}
                    </span>
                </div>
            </div>
        </Link>
    );
};

AdvertCard.Skeleton = function () {
    return (
        <div className="min-h-[254px] md:min-h-[417px] py-1.5 sm:py-2.5 md:py-5 px-2 sm:px-3.5 border border-brend-20 rounded-[15px] bg-white cursor-pointer hover:shadow-advert-card duration-100">
            <div className="w-full h-[120px] md:h-[190px] relative mb-2 md:mb-5">
                <Skeleton className="w-full h-full rounded-[15px]" />
            </div>
            <Skeleton className="h-5 w-3/4 mb-2" />
            <Skeleton className="h-4 w-1/2 mb-6" />
            <div className="flex flex-wrap items-center justify-between mb-6">
                <Skeleton className="h-6 w-3/5" />
                <Skeleton className="h-6 w-1/3" />
            </div>
            <div className="flex justify-between items-center mb-4">
                <Skeleton className="h-4 w-1/4" />
                <Skeleton className="h-4 w-1/4" />
                <Skeleton className="h-4 w-1/4" />
            </div>
            <div className="flex justify-between">
                <Skeleton className="h-4 w-1/3" />
                <Skeleton className="h-4 w-1/4" />
            </div>
        </div>
    );
};

export default AdvertCard;
