import React from 'react';
import { useTranslation } from 'react-i18next';

const CreateAdverInfoSection = () => {
    const { t, '1': i18n } = useTranslation();

    return (
        <div className="md:border-x-2 md:border-b-0 border-txt-40 h-full p-4">
            <h2 className="text-xl font-bold text-secondary-foreground">{t('advert.rules')}</h2>
            <ul className="flex flex-col gap-2 pl-2 py-4">
                {rules.map((rule, index) => (
                    <li
                        key={index}
                        className="text-xs text-muted-foreground tracking-wider list-disc"
                    >
                        {rule[i18n.language]}
                    </li>
                ))}
            </ul>
        </div>
    );
};

const rules: Record<string, string>[] = [
    {
        'az-AZ':
            'Bir ay ərzində eyni nömrədən 1 pulsuz (təkrar olmayan) elan yerləşdirmək olar. Elanı başqa bir elanla əvəz etmək qadağandır. Saytda artıq mövcud olan daşınmaz əmlakın təkrarən yerləşdirilməsi yalnız ödənişli əsaslarla mümkündür. Bununla belə, bir istifadəçi eyni daşınmaz əmlak obyektini yalnız bir dəfə yerləşdirə bilər.',
        'en-US':
            'You can place 1 free (non-recurring) ad from the same number within a month. It is forbidden to replace the ad with another ad. Reposting real estate objects already available on the site is only possible on a paid basis. However, a user can only place the same real estate object once.',
        'ru-RU':
            'Вы можете разместить 1 бесплатное (неповторяющееся) объявление с одного номера в течение месяца. Заменять объявление другим объявлением запрещено. Повторное размещение объектов недвижимости, уже доступных на сайте, возможно только на платной основе. Тем не менее, пользователь может разместить один и тот же объект недвижимости только один раз.',
    },
    {
        'az-AZ':
            'Əgər siz 1 ay ərzində 2 və daha artıq elan yerləşdirmək istəsəniz, hər növbəti elanın qiyməti - 5 AZN olacaq.',
        'en-US':
            'If you want to place 2 or more ads within 1 month, the price of each subsequent ad will be 5 AZN.',
        'ru-RU':
            'Если вы хотите разместить 2 или более объявлений в течение 1 месяца, стоимость каждого последующего объявления составит 5 AZN.',
    },
    {
        'az-AZ':
            'Elanlar vaxtından əvvəl silinsə də, nömrə üçün nəzərdə tutulmuş ödənişsiz yer, elan dərc ediləndən 30 gün sonra bərpa edilir.',
        'en-US':
            'Even if the ads are deleted before the time, the free space reserved for the number is restored 30 days after the ad is published.',
        'ru-RU':
            'Даже если объявления удалены до времени, бесплатное место, зарезервированное для номера, восстанавливается через 30 дней после публикации объявления.',
    },
    {
        'az-AZ':
            'darx.az saytında istifadə etdiyiniz ödənişli xidmətlər üçün nəzərdə tutulan məbləğ geri qaytarılmır.',
        'en-US': 'The amount allocated for the paid services you use on darx.az is not refundable.',
        'ru-RU':
            'Сумма, выделенная на оплачиваемые услуги, которые вы используете на darx.az, не возвращается.',
    },
    {
        'az-AZ':
            'Zəhmət olmasa elanı yerləşdirən zaman əlaqə vasitələrini (telefon nömrəsi, e-mail ünvanını) düzgün qeyd edin. Telefon nömrəsi ilə bağlı heç bir dəyişiklik həyata keçirilmir.',
        'en-US':
            'Please enter the contact information (phone number, email address) correctly when placing an ad. No changes are made to the phone number.',
        'ru-RU':
            'Пожалуйста, введите контактную информацию (номер телефона, адрес электронной почты) правильно при размещении объявления. Никаких изменений в номере телефона не производится.',
    },
    {
        'az-AZ': 'Elanınızla bağlı bütün məlumatlar sizin e-mail ünvanınıza göndərilir.',
        'en-US': 'All information related to your ad is sent to your email address.',
        'ru-RU':
            'Вся информация, связанная с вашим объявлением, отправляется на ваш адрес электронной почты.',
    },
    {
        'az-AZ':
            'Əmlakın təsvirində məlumatları böyük hərflərlə yazmaq, həmçinin telefon nömrəsi, e-mail ünvanı və şirkət haqqında xidmətləri yazmaq qadağandır.',
        'en-US':
            'It is forbidden to write information in capital letters in the description of the property, as well as to write phone numbers, email addresses, and services about the company.',
        'ru-RU':
            'Запрещается писать информацию заглавными буквами в описании недвижимости, а также писать номера телефонов, адреса электронной почты и услуги о компании.',
    },
    {
        'az-AZ': 'Əmlakın fasad (günorta vaxtı çəkilmiş) və otaq şəkilləri mütləq olmalıdır.',
        'en-US': 'The facade (taken at noon) and room photos of the property must be available.',
        'ru-RU': 'Фасад (сделанный в полдень) и фотографии комнат объекта обязательно должны быть.',
    },
    {
        'az-AZ':
            'Üzərində darx.az saytı da daxil olmaqla digər saytların loqotipləri olan şəkillər qəbul edilməyəcək.',
        'en-US': 'Images with logos of other sites, including darx.az, will not be accepted.',
        'ru-RU': 'Изображения с логотипами других сайтов, включая darx.az, не будут приняты.',
    },
    {
        'az-AZ':
            'Qiymət tam qeyd edilməlidir. (Qiyməti ilkin ödəniş və ya 1 sot, 1 m² üçün yazmaq olmaz)',
        'en-US':
            'The price must be fully stated. (It is not possible to write the price for the initial payment or 1 hundredth, 1 m²)',
        'ru-RU':
            'Цена должна быть полностью указана. (Невозможно указать цену за первоначальный взнос или 1 сотку, 1 м²)',
    },
    {
        'az-AZ': 'Ünvanı xəritədə dəqiq göstərməyiniz vacibdir.',
        'en-US': 'It is important to show the address accurately on the map.',
        'ru-RU': 'Важно точно указать адрес на карте.',
    },
    {
        'az-AZ': 'Fərqli vasitəçi və şirkətlər eyni elanı ödənişli yerləşdirə bilərlər.',
        'en-US': 'Different agents and companies can place the same ad for a fee.',
        'ru-RU': 'Разные агенты и компании могут разместить одно и то же объявление за плату.',
    },
];

export default CreateAdverInfoSection;
