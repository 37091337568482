import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { cn } from 'lib/utils';

type Props = {
    className?: string;
    error?: string;
    options: { label: string; value: string | number }[];
    value?: string | number;
    onChange: (value: string | number) => void;
    loading?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const SelectUnderline = ({
    error,
    className,
    options,
    value,
    loading = false,
    onChange,
    ...props
}: Props) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(value);
    const selectRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => setIsOpen((prev) => !prev);

    const handleOptionClick = (option: { label: string; value: string | number }) => {
        onChange(option.value);
        setIsOpen(false);
    };

    // Close the dropdown if clicked outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        setSelectedOption(value);
    }, [value]);

    const selectedOptionLabel =
        options.find((option) => option.value === selectedOption)?.label ?? t('general.select');

    return (
        <div className="w-full">
            <div className="relative " ref={selectRef}>
                <div
                    className={cn(
                        'max-w-full py-2 px-2.5 border-b border-txt-40 cursor-pointer placeholder:text-txt-40 placeholder:font-medium outline-none hover:border-primary/70 focus:border-primary duration-100 flex justify-between items-center',
                        className,
                    )}
                    onClick={toggleDropdown}
                    {...props}
                >
                    <span>{t(selectedOptionLabel)}</span>
                    <span className="ml-2">
                        <ArrowDownIcon
                            className={cn(
                                'duration-150 fill-txt-40 w-5 h-2.5 translate-y-0.5',
                                isOpen && 'rotate-180',
                            )}
                        />
                    </span>
                </div>

                {isOpen && (
                    <div className="absolute z-10 left-0 right-0 bg-white border border-txt-40 shadow-lg mt-1 max-h-[180px] overflow-y-auto">
                        {options.map((option) => (
                            <div
                                key={option.value}
                                onClick={() => handleOptionClick(option)}
                                className="px-4 py-2 cursor-pointer hover:bg-primary/10"
                            >
                                {t(option.label)}
                            </div>
                        ))}
                        {options.length === 0 &&
                            (loading ? (
                                <div className="px-4 py-2 cursor-pointer ">
                                    {t('general.loading')}
                                </div>
                            ) : (
                                <div className="px-4 py-2 cursor-pointer ">
                                    {t('general.no-options')}
                                </div>
                            ))}
                    </div>
                )}
            </div>
            <p className="text-red-600 text-xs h-4 mt-1">{error}</p>
        </div>
    );
};
